import { useFieldArray } from 'react-hook-form'

import ButtonPrimary from 'shared/Button/ButtonPrimary'
import NoUser from 'images/no-user.png'
import Avatar from 'shared/Avatar/Avatar'
import ButtonSecondary from 'shared/Button/ButtonSecondary'
import NcModal from 'shared/NcModal/NcModal';
import FormGroupContact from './FormGroupContact'
import Inputs from './Inputs'

const App = (props) => {

  const { config, control, inputFiles, setInputFiles, onBtnSubmitPress, onCancelPress, loading, values, getValues } = props

  const { fields, append, update, remove } = useFieldArray({ name: 'contacts', control });

  const handleAppend = (data, inputFiles) => {
    append(data)
    const inputName = `contacts.${fields.length}.profilePicture`
    setInputFiles( curr => ({ ...curr, [inputName]:  [ ...inputFiles.profilePicture ] }))
  }

  const handleUpdate = (index, data, inputFiles) => {
    update(index, data)
    const inputName = `contacts.${index}.profilePicture`
    setInputFiles( curr => ({ ...curr, [inputName]:  [ ...inputFiles.profilePicture ] }))    
  }

  const handleInputImageChange = (file, inputName) => {
      const { files } = file.target
      setInputFiles( curr => ({ ...curr, [inputName]:  [ ...files ] }))
  }

  let profilePictureSrc = null

  if(values?.profilePicture) {
      profilePictureSrc = `${values?.profilePicture}?size=200x200`
  }

  if(inputFiles?.profilePicture?.length) {
      profilePictureSrc = URL.createObjectURL(inputFiles.profilePicture[0])
  }

    return (
        <>
          <div className="space-y-5">
            {Object.keys(config.inputs).map( key => {

              if(key === 'profilePicture') return (
                  <div key={key} className="flex justify-center items-center mb-6 mt-6">
                      <div className="relative rounded-full overflow-hidden flex">
                        <Avatar sizeClass="w-44 h-44" imgUrl={profilePictureSrc || NoUser} />
                        <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                              stroke="currentColor" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round"
                            />
                          </svg>
                          <span className="mt-1 text-xs">{profilePictureSrc ? `Ubah Foto` : `Upload Foto`}</span>
                        </div>
                        <input type="file" onChange={ file => handleInputImageChange(file, 'profilePicture') } className="absolute inset-0 opacity-0 cursor-pointer"/>
                      </div>
                  </div>
              )

              return (
                <Inputs key={key} name={key} configInput={config.inputs[key]}  {...props} />
              )
              
            })}
          </div>

          <h3 className="text-2xl font-semibold pt-8">Daftar Pemain</h3>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className={`grid gap-4 md:gap-8 grid-cols-2 xl:grid-cols-4 pt-4`}>
              {fields.map((field, index) => {

                  return (
                    <div key={index}
                      className={`text-inherit relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-4 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]`}
                    >
                      <Avatar sizeClass="w-24 h-24 text-2xl" radius="rounded-full" imgUrl={`${field?.profilePicture || NoUser }`} userName={values.fullName} />
                      <div className="mt-3">
                        <h2 className={`text-base font-medium`}>
                        
                            <NcModal
                              renderTrigger={(openModal) => (
                                <ButtonSecondary className={`py-1 px-0`} onClick={() => openModal()}>{field.fullName}</ButtonSecondary>
                              )}
                              renderContent={(closeModal) => <FormGroupContact submit={(data, inputFiles) => handleUpdate(index, data, inputFiles)} onCancelPress={closeModal} values={field} />}
                              modalTitle={`Tambah ${index}`}
                            />
                        
                        </h2>
                      </div>
                    </div>
                  )
              })}

              <div 
                className={`text-inherit relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-4 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]`}
              >
                <Avatar sizeClass="w-24 h-24 text-2xl" radius="rounded-full" imgUrl={`${values?.profilePicture || NoUser }`} userName={values.fullName} />
                <div className="mt-3">
                  <h2 className={`text-base font-medium`}>
                  
                      <NcModal
                        renderTrigger={(openModal) => (
                          <ButtonSecondary className={`py-1 px-0`} onClick={() => openModal()}>Tambah</ButtonSecondary>
                        )}
                        renderContent={(closeModal) => <FormGroupContact submit={handleAppend} onCancelPress={closeModal} config={config} />}
                        modalTitle={`Tambah ${fields.length}`}
                        contentPaddingClass=""
                      />
                  
                  </h2>
                </div>
              </div>                        
          </div>          

          <div className="flex justify-center items-center pt-8 space-x-6">
            <ButtonSecondary disabled={loading} onClick={onCancelPress}>Kembali</ButtonSecondary>
            <ButtonPrimary loading={loading} disabled={loading} onClick={onBtnSubmitPress}>Kirim</ButtonPrimary>
            {/* <ButtonPrimary loading={loading} disabled={loading} onClick={handleShow}>Show</ButtonPrimary> */}
          </div>
        </>                
    )
}

export default App