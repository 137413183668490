import React from "react";
import Avatar from "shared/Avatar/Avatar"
import NoUser from "images/no-user.png"
import ButtonSecondary from 'shared/Button/ButtonSecondary'

const App = ({ data, filter }) => {
  let description = data.companyName || data.jobTitle
  if(data?.organization?.id) description = data.organization.fullName

  return (
    <div
      className={`text-inherit relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-4 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]`}
    >
      <Avatar sizeClass="w-28 h-28 text-2xl" radius="rounded-full" imgUrl={`${data?.profilePicture || NoUser }`} userName={data.fullName} />
      <div className="mt-3">
        <h2 className={`text-base font-medium`}>
          <ButtonSecondary className={`py-1 px-0`}>Tambah</ButtonSecondary>
        </h2>
        {filter === 'people' && (
          <span className={`block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400`}>{description}</span>
        )}
      </div>
    </div>
  );
};

export default App;
