// import Toast from 'react-native-simple-toast'
import toast, { Toaster } from 'react-hot-toast';



const joinedChannels = []
const joinedChannelData = {}

export default socket => {
    if (!socket) return

    const register = cb => {
        const params = { device: 0, appName: '' }
        socket.get('/socket/register', params, response => {
            console.log('socket.onConnect register - response:', response)
            if (cb) return cb(null, response)
        })
    }

    const rejoinChannels = () => {
        console.log(
            'socket.onConnect rejoinChannels - joinedChannels:',
            joinedChannels
        )
        joinedChannels.map(path => {
            console.log(
                'socket.onConnect rejoinChannels - joinedChannelData[path]:',
                joinedChannelData[path]
            )
            socket.joinChannel(path, joinedChannelData[path] || {})
        })
    }

    const onConnect = () => {
        toast('connect')
        register(() => rejoinChannels())
    }

    const onDisconnect = reason => {
        console.log('socket.onDisconnect - reason:', reason)
    }

    const onReconnect = attemptNumber => {
        console.log('socket.onReconnect - attemptNumber:', attemptNumber)
    }

    const onReconnectError = error => {
        console.log('socket.onReconnectError - error:', error)
    }

    const onMessage = data => {
        console.log('socket.onMessage - data:', data)
        // Toast.show(data.title, Toast.LONG)
        toast(data.title)
    }

    const onEvent = data => {
        console.log('socket.onEvent - data:', data)
    }

    const onError = error => {
        console.log('socket.onError - error:', error)
    }

    const onTestBlast = data => {
        console.log('socket.onTestBlast - data:', data)
        // Toast.showWithGravity(data.title, Toast.SHORT, Toast.BOTTOM)
        toast(data.title)
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    socket.on('reconnect', onReconnect)
    socket.on('reconnect_error', onReconnectError)
    socket.on('message', onMessage)
    socket.on('event', onEvent)
    socket.on('error', onError)
    socket.on('test blast', onTestBlast)
}
