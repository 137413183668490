import React from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

const Pagination = ({ className = "", onChange, page, count }) => {
  const renderItem = key => {

    if (key === (page - 1 )) {
      // RETURN ACTIVE PAGINATION
      return (
        <span
          key={key}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
        >
          {key + 1}
        </span>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <Link
        key={key}
        className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
        to={`#`}
        onClick={() => onChange(key + 1)}
      >
        {key + 1}
      </Link>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {[...Array(count).keys()].map(renderItem)}
    </nav>
  );
};

export default Pagination;
