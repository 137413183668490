import React from "react";
import { Page } from "./types";
import PageHome from "../home";

import event from "modules/event/router"
import accommodation from "modules/accommodation/hotel/router"

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },  
  ...accommodation, ...event
];

export default pages;
