import React from 'react'
// import { Box, Button, Typography } from '@mui/material'

import FormLayoutDefault from './react'

const styles = {
    container: {
        padding: 16,
        top: undefined
    }
}

const App = props => {
    const { FormLayoutComponent, onBtnSubmitPress, config, loading, errors } = props
    console.log('builder/form/view/index errors: ', errors)

    const errorFields = Object.keys(errors)
    const errorFieldLabels = []
    if (errorFields.length) errorFields.map( field => errorFieldLabels.push(config.inputs[field].label))

    return (
        <>
            {errorFieldLabels.length ? (
                <div style={{ backgroundColor: 'red', padding: 16 }}>
                    <div style={{ color: 'white' }}>{`Error: ${errorFieldLabels.join(', ')}`}</div>
                </div>
            ) : null }

            {FormLayoutComponent && <FormLayoutComponent {...props} />}
            {!FormLayoutComponent && (
                <FormLayoutDefault
                    onBtnSubmitPress={onBtnSubmitPress}
                    {...props}
                />
            )}

            {!config.hideBtnSubmit && (
            <div
                // style={[
                //     styles.container,
                //     config.type === 'modal' && {
                //         borderBottomLeftRadius: 8,
                //         borderBottomRightRadius: 8
                //     }
                // ]}
            >
                <div
                    variant="contained"
                    onClick={onBtnSubmitPress}
                    disabled={loading}
                >
                    {loading ? 'submitting' : 'Submit'}
                </div>
            </div>
            )}

        </>
    )
}

export default App