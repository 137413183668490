import axios from 'axios'

import { getItem } from './storage'

const CancelToken = axios.CancelToken
export { CancelToken }

const requestApi = axios.create()

requestApi.interceptors.request.use(async config => {
    const { url, key } = require('../config').default.api
    config.baseURL = url
    const accessToken = await getItem('@accessToken')
    if (accessToken !== null)
        config.headers.Authorization = `Bearer ${accessToken}`
    switch (config.method) {
        case 'get':
        default:
            if (config.params === undefined) config.params = {}
            if (config.params.accessToken) {
                config.headers.Authorization = `Bearer ${config.params.accessToken}`
                delete config.params.accessToken
            }
            if (key) config.params.appKey = key
            break
        case 'post':
            if (config.data === undefined) config.data = {}
            if (config.data.accessToken) {
                config.headers.Authorization = `Bearer ${config.data.accessToken}`
                delete config.data.accessToken
            }
            if (key) {
                config.data.appKey = key
                // kasus upload image, config.data.appKey ga kebaca di server, jd nya taro di url param
                if (config.headers['Content-Type'] === 'multipart/form-data') {
                    if (config.params === undefined) config.params = {}
                    config.params.appKey = key
                }
            }
            break
    }
    return config
})

requestApi.interceptors.response.use(
    response => response.data,
    error => {
        let message = null
        if (error.response) {
            if (error.response.data.data) message = error.response.data.data
        } else message = error.message
        return Promise.reject(message || error)
    }
)

export default requestApi
