// import formUtils from 'aksimaya/components/Builder/libs/utils'

// const config = {
//     list: {},

//     form: {
//         type: 'external',
//         externalUrl: '/account/my/form',

//         createUrl: '/account/my/create',
//         updateUrl: '/account/my/update',
        
//         title: 'Form Pendaftaran Klub Bola',
//         hideBtnSubmit:  true,
        
//         inputs: {
//             profilePicture: {
//                 label: 'Gambar',
//                 dataType: 'string',
//                 defaultsTo: '',
//                 placeholder: 'gambar',
//                 type: 'file',
//                 uploadUrl: '/account/image/upload',
//                 rules: {}                
//             },
//             coverPicture: {
//                 label: 'Gambar',
//                 dataType: 'string',
//                 defaultsTo: '',
//                 placeholder: 'gambar',
//                 type: 'file',
//                 uploadUrl: '/account/image/upload',
//                 rules: {}                
//             },                        
//             shortName: {
//                 type: 'text',
//                 label: 'Nama',
//                 dataType: 'string',
//                 defaultsTo: '',
//                 placeholder: 'nama',
//                 rules: {
//                     required: 'nama belum diisi'
//                 }
//             },
//             locationAddress: {
//                 type: 'text',
//                 label: 'Alamat',
//                 dataType: 'string',
//                 defaultsTo: '',
//                 placeholder: 'alamat',
//                 rules: {}
//             },            
//             phoneNumber: {
//                 type: 'text',
//                 label: 'No Telp',
//                 dataType: 'string',
//                 defaultsTo: '',
//                 placeholder: 'nomor telepon',
//                 rules: {
//                     required: 'nomor telepon belum diisi'
//                 }
//             },
//             email: {
//                 type: 'text',
//                 label: 'Email',
//                 dataType: 'string',
//                 defaultsTo: '',
//                 placeholder: 'email',
//                 rules: {}
//             }                                           
//         },

//         calculateFormValues: (values) => {
//             console.log('event config calculateFormValues loaded values: ', values)

//             let calculatedValues = JSON.parse(JSON.stringify(values))
//             calculatedValues.coordinate = null

//             if (values.lat && values.lng) {
//                 calculatedValues.coordinate = `${values.lat},${values.lng}`
//                 delete calculatedValues.lat
//                 delete calculatedValues.lng
//             }
        
//             return calculatedValues
//         },
        
//         calculateSubmitData: (validatedData, values, rootParams) => {
//             console.log('event config calculateSubmitData loaded validatedData: ', validatedData)
//             console.log('event config calculateSubmitData loaded rootParams: ', rootParams)
    
//             const copiedValidatedData = JSON.parse(JSON.stringify(validatedData))

//             if (validatedData && validatedData.coordinate) {
//                 delete copiedValidatedData.coordinate

//                 const [lat, lng] = validatedData.coordinate.split(',')
//                 copiedValidatedData.lat = lat
//                 copiedValidatedData.lng = lng
//             }

//             copiedValidatedData.types = 'club_soccer'

//             return copiedValidatedData
//         }        
//     }
// }

// config.form.defaultValues = formUtils.defaultValues(config.form.inputs)

// const dataToRender = (responseData) => {
//     return responseData
// }

// export default { ...config, dataToRender}

import formUtils from 'components/Builder/libs/utils'

const config = {
    list: {},

    form: {
        type: 'external',
        externalUrl: '/account/user/form',

        createUrl: '/event/page/participant/create',
        updateUrl: '/account/user/update',
        afterSubmitPath: '/club/soccer/squads',
        
        title: 'Form Participant',
        hideBtnSubmit:  true,

        inputs: {
            profilePicture: {
                label: 'Foto',
                dataType: 'string',
                defaultsTo: '',
                placeholder: 'foto',
                type: 'file',
                uploadUrl: '/event/page/participant/image/upload',
                rules: {
                    required: 'foto belum diisi'
                }                
            },

            isOrganization: {
                label: 'Is Organization',
                dataType: 'boolean',
                defaultsTo: false,
                placeholder: '',
                type: 'switch'
            },     
                        
            fullName: {
                type: 'text',
                label: 'Nama Lengkap',
                dataType: 'string',
                defaultsTo: '',
                placeholder: 'nama lengkap',
                rules: {
                    required: 'nama lengkap belum diisi'
                },
            },
            companyName: {
                type: 'text',
                label: 'Nama Instansi',
                dataType: 'string',
                defaultsTo: '',
                placeholder: 'nama instansi',
                rules: {
                    required: 'nama instansi belum diisi'
                },
            },            
            jobTitle: {
                type: 'text',
                label: 'Jabatan',
                dataType: 'string',
                defaultsTo: '',
                placeholder: 'jabatan',
                rules: {
                    required: 'jabatan belum diisi'
                },
            },
            email: {
                type: 'text',
                label: 'Email',
                dataType: 'string',
                defaultsTo: '',
                placeholder: 'email',
                rules: {
                    required: 'email belum diisi'
                },
            },
            mobilePhone: {
                type: 'text',
                label: 'Nomor Handphone',
                dataType: 'string',
                defaultsTo: '',
                placeholder: 'nomor handphone',
                rules: {
                    required: 'nomor handphone belum diisi'
                },
            }            
        },

        calculateFormValues: (values) => {
            console.log('event config calculateFormValues loaded values: ', values)

            let calculatedValues = JSON.parse(JSON.stringify(values))
            calculatedValues.coordinate = null

            if (values.lat && values.lng) {
                calculatedValues.coordinate = `${values.lat},${values.lng}`
                delete calculatedValues.lat
                delete calculatedValues.lng
            }
        
            return calculatedValues
        },
        
        calculateSubmitData: (validatedData, values, rootParams) => {
            console.log('event config calculateSubmitData loaded validatedData: ', validatedData)
            console.log('event config calculateSubmitData loaded rootParams: ', rootParams)
    
            const copiedValidatedData = JSON.parse(JSON.stringify(validatedData))
            if (rootParams.event) copiedValidatedData.event = rootParams.event
            if (rootParams.type) copiedValidatedData.type = rootParams.type

            if (validatedData && validatedData.coordinate) {
                delete copiedValidatedData.coordinate

                const [lat, lng] = validatedData.coordinate.split(',')
                copiedValidatedData.lat = lat
                copiedValidatedData.lng = lng
            }

            return copiedValidatedData
        },        
    }
}

config.form.defaultValues = (inputs) => {
    return formUtils.defaultValues(inputs ? inputs : config.form.inputs)
}

const dataToRender = (responseData) => {
    return responseData
}

export default { ...config, dataToRender}