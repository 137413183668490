// import AsyncStorage from '@react-native-async-storage/async-storage'
const __DEV__ = true 

const AsyncStorage = localStorage

const { name } = require('../config').default
const PREFIX = name ? `${name}_` : ''

export const setItem = async (key, value) => {
    key = `${PREFIX}${key}`
    try {
        return await AsyncStorage.setItem(key, value)
    } catch (error) {
        __DEV__ && console.error(error)
    }
}

export const setItems = async keyValuePairs => {
    keyValuePairs.map(keyValue => (keyValue[0] = `${PREFIX}${keyValue[0]}`))
    try {
        return await AsyncStorage.multiSet(keyValuePairs)
    } catch (error) {
        __DEV__ && console.error(error)
    }
}

export const getItem = async key => {
    key = `${PREFIX}${key}`
    try {
        const value = await AsyncStorage.getItem(key)
        return value
    } catch (error) {
        __DEV__ && console.error(error)
    }
}

export const getItems = async keys => {
    keys = keys.map(key => `${PREFIX}${key}`)
    try {
        const values = await AsyncStorage.multiGet(keys)
        return values
    } catch (error) {
        __DEV__ && console.error(error)
    }
}

export const removeItem = async key => {
    key = `${PREFIX}${key}`
    try {
        return await AsyncStorage.removeItem(key)
    } catch (error) {
        __DEV__ && console.error(error)
    }
}

export const removeItems = async keys => {
    keys = keys.map(key => `${PREFIX}${key}`)
    try {
        return await AsyncStorage.multiRemove(keys)
    } catch (error) {
        __DEV__ && console.error(error)
    }
}

export const clear = async () => {
    try {
        return await AsyncStorage.clear()
    } catch (error) {
        __DEV__ && console.error(error)
    }
}
