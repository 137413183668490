import React, { FC } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";

export interface HeaderContentProps {
  heading: ReactNode;
  subHeading?: ReactNode;
  onClickRightBtn: (item: string) => void;
}

const HeaderContent: FC<HeaderContentProps> = ({
  subHeading = "",
  heading = "🎈 Latest Articles",
  onClickRightBtn
}) => {

  return (
    <div className="flex flex-col mb-8 relative">
    <div className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between`}>
      <div className={"max-w-2xl"}>
        <h2 className={`text-3xl md:text-4xl font-semibold`}>{heading}</h2>
        {subHeading && (
          <span className="mt-2 md:mt-4 font-normal block text-base sm:text-lg text-neutral-500 dark:text-neutral-400">
            {subHeading}
          </span>
        )}
      </div>
      <span className="hidden sm:block flex-shrink-0">
          <ButtonSecondary className="!leading-none" onClick={() => onClickRightBtn('accommodation')}>
            <span>Lihat Semua</span>
            <i className="ml-3 las la-arrow-right text-xl"></i>
          </ButtonSecondary>
        </span>
    </div>
    </div>
  );
};

export default HeaderContent;
