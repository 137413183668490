import React from "react";

import SectionHero from "./SectionHero";
import SectionSliderNewCategories from "modules/accommodation/hotel/components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "./SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "modules/accommodation/hotel/components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "./SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

import HeroSearchForm2MobileFactory from "modules/accommodation/hotel/components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";

import requestApi from "services/requestApi";
import { parseResponseData } from "modules/accommodation/hotel/portal/home"
import HeadTags from "modules/accommodation/hotel/components/SEO/HeadTags";

const DEMO_CATS = [
  {
    id: "1",
    href: "/accommodation",
    name: "Puncak",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/accommodation",
    name: "Cibinong",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "2",
    href: "/accommodation",
    name: "Ciawi",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "2",
    href: "/accommodation",
    name: "Cigudeg",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/accommodation",
    name: "Ciampea",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "2",
    href: "/accommodation",
    name: "Cariu",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

function PageHome() {

  const [hotels, setHotels] = React.useState({ data: [], metadata: {}, hasMore: false })

  const getHotels = async () => {
    const params = { collect: 'productCategory', productCategory: 'hotel', limit: 8, sort: 'createdAt desc'}
    const response = await requestApi('/accommodation/hotel/sales/accounts', { params })
    const parsedData = response.data.map( row => parseResponseData(row))
    setHotels(current => ({ ...current, data: parsedData, metadata: response.metadata }))
  }

  React.useEffect(() => {
    getHotels()
  }, [])

  return (
    <>
      <HeadTags title="GoBogor | Event Registration" /> 
      <div className="relative overflow-hidden">
        <BgGlassmorphism />

        <div className="container relative space-y-24 lg:space-y-28 mb-24 lg:mb-28">
          {/* <SectionHero className="pt-4 lg:pt-12" /> */}
          <div className="relative py-16 mt-0 lg:mt-6">
            <BackgroundSection />
            <SectionBecomeAnAuthor />
          </div>
                    
          <SectionHowItWork />
          <SectionOurFeatures />
          <SectionSubscribe2 />
          {/* <SectionGridCategoryBox />

          <div className="relative py-16">
            <BackgroundSection />
            <SectionBecomeAnAuthor />
          </div> */}

          {/* SECTION 1 */}
          {/* <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            uniqueClassName="PageHome_s3"
          /> */}

          {/* SECTION */}
          <SectionVideos />

          {/* SECTION */}
          <div className="relative py-16">
            <BackgroundSection />
            <SectionClientSay uniqueClassName="PageHome_" />
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHome;
