import React from "react";
import { Page } from "./types";
import PageHome from "../home";

import account from "modules/account/router"
import accommodation from "modules/accommodation/router"
import event from "modules/event/router"
import link from "modules/link/router"
import services from "modules/services/router"

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  ...account, ...accommodation, ...event, ...link, ...services
];

export default pages;
