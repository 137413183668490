import StartRating from "components/StartRating/StartRating";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { useSelector } from "react-redux";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const event = useSelector((state: any) => state.event)

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Terima Kasih 🎉
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Pendaftaran Acara</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                {/* <NcImage src="https://images.pexels.com/photos/6373478/pexels-photo-6373478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" /> */}
                <NcImage src={`${event.page.cart.profilePicture}`} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {event.page.cart.name}
                </span>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {event.page.cart.locationName},
                </span>         
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {event.page.cart.locationAddress}
                </span>                       
              </div>
              {/* <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                2 beds · 2 baths
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <StartRating /> */}
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Info Pendaftaran</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Booking code</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                #222-333-111
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                12 Aug, 2021
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Nama Lengkap</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                xxxxxx
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Instansi</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                xxxxx
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Jabatan</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                xxxxx
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Email</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                xxxxx
              </span>
            </div>  
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Nomor Handphone</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                xxxxx
              </span>
            </div>                                  
          </div>
        </div>
        <div>
          <ButtonPrimary href={`/event/${event.page.cart.slug}`}>Kembali ke halaman acara</ButtonPrimary>
        </div>
      </div>
    );
  };

  if (!event?.page?.cart?.id) {
    return (
      <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
        <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
          Acara belum dipilih.
        </main>
      </div>      
    )
  }

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
