import React from "react";
import moment from "moment";
import { AES, enc } from "crypto-js";
import { useLocation } from "react-router-dom";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import Avatar from "shared/Avatar/Avatar";

import requestApi from "services/requestApi";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const App = ({ className = "" }) => {
  const query = useQuery();

  const [profile, setProfile] = React.useState({ loading: true, event: {}, type: { formFields: [] } });

  const getParticipantDetails = React.useCallback(async () => {
    const params = { id : query.get('id'), collect: 'event,category' };
    const response = await requestApi('/event/page/participants', { params });
    if (response.data.length) {
      const responseProfile = response.data[0]

      if(responseProfile.encryptedEmail)
        responseProfile.email = AES.decrypt(responseProfile.encryptedEmail, 'MmLirgUqxwQepfQn').toString(enc.Utf8);
      if(responseProfile.encryptedMobilePhone)
        responseProfile.mobilePhone = AES.decrypt(responseProfile.encryptedMobilePhone, 'MmLirgUqxwQepfQn').toString(enc.Utf8);

      setProfile( curr => ({ ...curr, loading: false, ...responseProfile }))
    } else {
      setProfile( curr => ({ ...curr, loading: false }))
    }
  }, [])

  React.useEffect(() => {
    getParticipantDetails();
  }, []);      

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        {query.get('referrer') === 'form' && (
          <>
          <h2 className="text-3xl lg:text-4xl font-semibold">Terima Kasih 🎉</h2>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          </>
        )}

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Pendaftaran Acara</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className="aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={`${profile.event.profilePicture}`} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {profile.event.name}
                </span>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {profile.event.locationName},
                </span>         
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {profile.event.locationAddress}
                </span>                       
              </div>
              {/* <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                2 beds · 2 baths
              </span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <StartRating /> */}
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Info Pendaftaran</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Registration code</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {profile.id}
              </span>
            </div>    
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Registration Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {moment(profile.createdAt).format('DD MMMM YYYY')}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Category</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100 capitalize">
                {profile.category.name}
              </span>
            </div>

            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

            {profile.category.formFields.map( (field, idx) => {
              let additionalClass = ''
              if(field.name === 'email') additionalClass = 'lowercase'
              if(field.name === 'fullName') additionalClass = 'capitalize'

              if( field.name === 'profilePicture') return (
                <div key={idx} className="flex justify-center items-center" style={{ marginBottom: '1rem' }}>
                  <div className="relative rounded-full overflow-hidden flex">
                    <Avatar sizeClass="w-44 h-44" imgUrl={`${profile.profilePicture}?size=400x400`} />
                  </div>
                </div>
              )

              return (
                <div key={idx} className="flex text-neutral-6000 dark:text-neutral-300">
                  <span className="flex-1">{field.label || field.name}</span>
                  <span className={`flex-1 font-medium text-neutral-900 dark:text-neutral-100 ${additionalClass}`}>
                    {profile[field.name]}
                  </span>
                </div>
              )
            })} 

            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Status</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100 capitalize">
                {profile.status}
              </span>
            </div>            

            <div className="flex justify-center items-center" style={{ marginBottom: '1rem' }}>
              <Zoom zoomMargin={4}>
                <img width="200" src={`https://gobogor.id/api/tools/generator/qr?content=event:participant:${profile.id}&width=500`} />
              </Zoom>
            </div>
            <div className="flex justify-center items-center" style={{ marginBottom: '1rem' }}>
              klik pada barcode untuk zoom
            </div>            

          </div>
        </div>
        <div className="flex justify-center items-center">
          <ButtonPrimary href={`/event/${profile.event.slug ?? profile.event.id }`}>Kembali ke halaman acara</ButtonPrimary>
        </div>
      </div>
    );
  };

  // if (!event?.page?.cart?.id) {
  //   return (
  //     <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
  //       <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
  //         Acara belum dipilih.
  //       </main>
  //     </div>      
  //   )
  // }

  return (
    <div className={`${className}`} data-nc-id="PayPage">
      <main className="container mt-8 mb-24 lg:mb-32 ">
        {profile.loading && (
        <div className="flex justify-center items-center">
          Loading...
        </div>
        )}

        {!profile.loading && !profile.id && (
        <div className="flex justify-center items-center">
          Data Tidak Diketemukan
        </div>
        )}

        {!profile.loading && profile.id && (
          <div className="max-w-4xl mx-auto">{renderContent()}</div>
        )}
      </main>
    </div>
  );
};

export default App;
