// import { Box, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import renderInput from './renderInput'

const App = (props) => {
    const { config, hideInput, model, onBtnSubmitPress, onResetPress, onCancelPress } = props

    return (
        <>
            {/* {Object.keys(model.attributes).map((name, idx) => {
                // console.log('fieldName: ', fieldName)
                // untuk model ditaro disini biar proses nya cuma 1 level aja
                if (model.attributes[name].model && !hideInput[fieldName]) {
                    return (
                        <Fragment key={name}>
                            <h4>{model.attributes[name].label} {model.attributes[name].require && <span className='text-danger'>*</span>}</h4>   
                            {Object.keys(model.attributes[name].model.attributes).map((modelFieldName, modelIdx) => (
                                renderInput(`${name}_${modelFieldName}`, modelIdx, modelFieldName)
                            ))}
                        </Fragment>
                    )
                } else {
                    return (
                        <Fragment key={name}>
                            {renderInput({name, ...props})}
                        </Fragment>
                    )
                }
            })} */}

        {Object.keys(props.config.inputs).map(name =>
            props.config.inputs[name].model && !hideInput[name] ? (
                !props.config.inputs[name].hidden && (
                    <Fragment key={name}>
                        {/* <Box>
                            <Typography>{config.inputs[name].label}</Typography>
                            {config.inputs[name].require && <Typography>*</Typography>}
                        </Box> */}
                        {Object.keys(config.inputs[name].config.inputs).map(
                            (modelFieldName, modelIdx) =>
                                renderInput(
                                    `${name}_${modelFieldName}`,
                                    modelIdx,
                                    modelFieldName
                                )
                        )}
                    </Fragment>
                )
            ) : (
                !props.config.inputs[name].hidden && (
                    <Fragment key={name}>
                        {renderInput({ name, ...props })}
                    </Fragment>
                )
            )
        )}            
        </>
    )    
}

export default App