import { MapPinIcon } from "@heroicons/react/24/solid";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import Label from "components/Label/Label";
import GoogleMapReact from "google-map-react";
import React, { FC } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

export interface PageAddListing2Props {}

const defaultCenter = {
  // bogor
  lat: -6.5817484, lng: 106.8107553
}

const PageAddListing2: FC<PageAddListing2Props> = () => {


  const [state, setState] = React.useState<any>({
    mapApiLoaded: false, mapInstance: null, mapApi: null, geoCoder: null,
    zoom: 18,
    address: '',
    draggable: true,
    lat: defaultCenter.lat, lng: defaultCenter.lng, center: [defaultCenter.lat, defaultCenter.lng],    
    places: []
  })

  React.useLayoutEffect(() => {
    console.log('state.mapApiLoaded: ', state.mapApiLoaded)

    if (state.mapApiLoaded) {
      initAfterLoadMap(state.mapApi)
    }

  }, [])  

  const onChange = ({ center, zoom }: any) => {
      console.log('onChange center: ', center)
      console.log('onChange zoom: ', zoom)
  }

  const getAddresses = (mapApi: any, lat: any, lng: any) => {

      return new Promise((resolve, reject) => {

          const geocoder = new mapApi.Geocoder
          geocoder.geocode({ 'location': { lat, lng } }, (results: any, status: any) => {
            if (status === 'OK') {
                if (results[0]) resolve(results[0])
                else reject('No results found')
            } else {
              reject('Geocoder failed due to: ' + status)
            }
          })
      })
  }

const getPosition = () => {
  const geolocationOptions = { enableHighAccuracy: true, maximumAge: 0, timeout: Infinity }

  return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, geolocationOptions)
  })
}

const initAfterLoadMap = async (mapApi: any) => {
  if ('geolocation' in navigator) {
    const position: any = await getPosition()
    const { latitude: currLat, longitude: currLng } = position.coords

    const addresses: any = await getAddresses(mapApi, currLat, currLng)
    addresses.parsedAddressComponents = addresses.address_components.reduce((seed: any, { long_name, types }: any) => (types.forEach((t: any) => seed[t] = long_name), seed), {})
    console.log('addresses: ', addresses)

    setState((current: any) => ({
      ...current,
      center: [currLat, currLng], lat: currLat, lng: currLng,
      address: addresses.formatted_address
    }))
  }
}

  const loadMap = (mapInstance: any, mapApi: any) => {
    console.log('loadMap loaded')

    setState((current: any) => ({
      ...current, mapApiLoaded: true, mapInstance, mapApi
    }))

    initAfterLoadMap(mapApi)
  }

  return (
    <CommonLayout
      index="02"
      nextHref="/add-listing-3"
      backtHref="/add-listing-1"
    >
      <>
        <h2 className="text-2xl font-semibold">Your place location</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          <ButtonSecondary>
            <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
            <span className="ml-3">Use current location</span>
          </ButtonSecondary>
          {/* ITEM */}
          <FormItem label="Country/Region">
            <Select>
              <option value="Puncak">Puncak</option>
              <option value="Padjajaran">Padjajaran</option>
              <option value="Cibinong">Cibinong</option>
              <option value="Cisarua">Cisarua</option>
            </Select>
          </FormItem>
          <FormItem label="Street">
            <Input placeholder="..." />
          </FormItem>
          <FormItem label="Room number (optional)">
            <Input />
          </FormItem>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-5">
            <FormItem label="City">
              <Input />
            </FormItem>
            <FormItem label="State">
              <Input />
            </FormItem>
            <FormItem label="Postal code">
              <Input />
            </FormItem>
          </div>
          <div>
            <Label>Detailed address</Label>
            <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
              1110 Pennsylvania Avenue NW, Washington, DC 20230
            </span>
            <div className="mt-4">
              <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
                <div className="rounded-xl overflow-hidden">
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyCczSaO4uq6nnomo3pa1CKAC28WpVYI4Kg",
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={18}
                    defaultCenter={JSON.parse(JSON.stringify(defaultCenter))}
                    zoom={state.zoom}
                    center={state.center}
                    onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
                    onChange={onChange}
                  >
                    {/* <LocationMarker lat={55.9607277} lng={36.2172614} /> */}
                  </GoogleMapReact>

    <div className="text-primary-500 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-11 w-11"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
          clipRule="evenodd"
        />
      </svg>
    </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListing2;
