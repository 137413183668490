import React from "react"
import { useParams } from "react-router-dom"
import requestApi from "services/requestApi"

const App = ({ className = "" }) => {
    const routerParams = useParams()

    const [ state, setState ] = React.useState({ loading: true, data: [] })

    const getData = async () => {
        const params = { slug: routerParams.slug }
        try {
            const response = await requestApi(`/link/go`, { params })
            const { data } = response
            setState({ loading: false, data })

            if(data && data.length) {
                window.location.replace(`${data[0].link}`)
            }
        } catch (error) {
            
        }
    }

    React.useEffect(() => {
        getData()
    }, [])

    return (
        <div className={`${className}`}>
            <main className="container mt-11 mb-24 lg:mb-32 ">
                <div className="max-w-4xl mx-auto">
                    {state.loading && !state.data.length ? (
                        <div>Loading...</div>
                    ) : null}

                    {!state.loading && !state.data.length ? (
                        <div>Invalid Short URL</div>
                    ) : null}          
                    {!state.loading && state.data.length ? (
                        <div>
                            <div>GO TO LINK</div>
                            <a href={state.data[0].link}>{state.data[0].link}</a>                            
                        </div>
                    ) : null}
                </div>                      
            </main>
        </div>
    )
}

export default App
