import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "modules/accommodation/hotel/components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import React, { FC } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import requestApi from "services/requestApi";
import HeroSearchForm2MobileFactory from "modules/accommodation/hotel/components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import HeadTags from "components/SEO/HeadTags";

export interface ListingStayPageProps {
  className?: string;
}

export const parseResponseData = (row: any) => {

  const images = row.images.map( (row: any) => row.image)
  const oReturn = {
    galleryImgs: [ row.profilePicture, ...images ],
    listingCategory: row.productCategory || {},
    address: `${row.locationAddress}`,
    title: row.shortName,
    bedrooms: 3,
    href: `/accommodation/${row.shortName.split(' ').join('-')}-${row.id}`,
    like: false,
    saleOff: true,
    isAds: false,
    price: `Rp 10000`,
    reviewStart: '4.5',
    reviewCount: 45,
    id: row.id,      
  }

  return oReturn
}

const ListingStayPage: FC<ListingStayPageProps> = ({ className = "" }) => {

  const appConfig = require('config').default
  const [hotel, setHotel] = React.useState({ data: [], metadata: {}, hasMore: false })

  const getHotel = async () => {
    const params = { collect: 'productCategory', productCategory: 'hotel' }
    // /property/sales/accounts?limit=30&sort=createdAt+DESC&startDate=2023-02-23&endDate=2023-02-24&qty=1&availableOnly=true&appKey=PPBJLUXKeggU6RmP9jkpCqYpLv4FKSuU
    const response: any = await requestApi('/property/sales/accounts', { params })
    const parsedData = response.data.map( (row: any) => parseResponseData(row))
    setHotel(current => ({ ...current, data: parsedData, metadata: response.metadata }))
  }

  React.useEffect(() => {
    getHotel()
  }, [])

  return (
    <>
      <HeadTags title={`${appConfig.name} | Events | Acara`} />
      <div className={`sticky top-0 w-full left-0 right-0 z-40 nc-header-bg`}>
        <div className="px-5 py-3 lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div>
      </div>    

    <div className={`nc-ListingStayPage relative overflow-hidden ${className}`} data-nc-id="ListingStayPage">
      <BgGlassmorphism />

      {/* <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3 pt-5">
          <HeroSearchForm2MobileFactory />
      </div>       */}

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        <SectionHeroArchivePage title="Acara Bogor" currentPage="Events" currentTab="Events" className="pt-10 pb-24 lg:pb-28 lg:pt-16 " />

        {/* SECTION */}
        <SectionGridFilterCard className="pb-24 lg:pb-28" data={hotel.data} />

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
          />
        </div>

        {/* SECTION */}
        <SectionSubscribe2 className="py-24 lg:py-28" />

        {/* SECTION */}
        <div className="relative py-16 mb-24 lg:mb-28">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div>
      </div>
    </div>
    </>
  );
};

export default ListingStayPage;
