import Helmet from "react-helmet";

const HeadTags = (props) => {
  
  const {
    title = "",
    description = "",
    url= "",
    image = ""
  } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />

      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="description" key="description" content={description} />
      <meta name="title" key="title" content={title} />
      
      <meta property="og:title" key="og:title" content={title} />
      <meta property="og:locale" key="og:locale" content="en_US" />
      <meta property="og:type" key="og:type" content="website" />
      <meta property="og:description" key="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" key="og:image" content={image} />  
    </Helmet>
  );
};

export default HeadTags;