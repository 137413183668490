import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import requestApi from 'services/requestApi'

import ViewComponent from './view'
import formUtils from '../libs/utils'
import fileFormAppend from './fileFormAppend'

const App = props => {
    const {
        config,
        rootParams,
        FormLayoutComponent,
        render,
        values,
        onSubmitPress,
        onCancelPress,
        // onResetPress,
        onSubmitFinish,
        accessToken,
        accountMode
    } = props

    const [inputSelectOptions, setInputSelectOptions] = useState({})
    const [inputSelected, setInputSelected] = useState({})
    const [inputFiles, setInputFiles] = useState({})
    // const [hideInput, setHideInput] = useState({})

    const [loading, setLoading] = useState(false)

    // untuk mengetahui input mana yg berubah. Ini berguna untuk proses edit, yg di kirim ke server hanya field yg berubah saja
    // const [modifiedInputs, setModifiedInputs] = useState([])
    const modifiedInputsRef = useRef([])
    const firstLoad = useRef(false)

    const finalValues = config.calculateFormValues ? config.calculateFormValues(JSON.parse(JSON.stringify(values))) : values

    const {
        control,
        getValues,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        reset,
        watch,
        trigger,
        formState: { errors, isSubmitting }
    } = useForm({ defaultValues: finalValues || {} })

    const calculateHideInput = (fieldName, value) => {
        const result = {}

        for (const key of Object.keys(config.inputs)) {
            if (
                config.inputs[key] &&
                config.inputs[key].showWhen &&
                config.inputs[key].showWhen[fieldName]
            ) {
                result[key] = !(
                    config.inputs[key].showWhen[fieldName] === value[fieldName]
                )
                // setHideInput(current => ({ ...current, ...result }))
            }
        }
    }

    const submitFiles = async data => {
        for await (const key of Object.keys(inputFiles)) {
            if (inputFiles[key] && inputFiles[key].length) {
                const formData = new FormData()

                // diulik lg nanti, konsep disini ga jalan, malah error di requestApi nya
                // formData.append("appKey", 'PPBJLUXKeggU6RmP9jkpCqYpLv4FKSuU')
                
                inputFiles[key].forEach((image, i) => {

                    // karena native & js beda jd dipisah di fileFormAppend

                    // https://stackoverflow.com/questions/52936988/react-native-axios-trying-to-upload-image
                    // formData.append('file', {
                    //     ...image,
                    //     //   uri: Platform.OS === 'android' ? image.uri : image.uri.replace('file://', ''),
                    //     name: `image-${i}.jpg` // klo ga da ini, diserver ga jalan
                    //     // ,
                    //     //   type: 'image/jpeg', // it may be necessary in Android.
                    // })
                    
                    formData.append('file', fileFormAppend(image, i))
                })

                let isKeyFieldArray = false
                let splittedKey = []

                if(key.includes('.')) {
                    isKeyFieldArray = true
                    splittedKey = key.split('.')
                    console.log('submitFiles splittedKey: ', splittedKey)
                }

                const url = isKeyFieldArray ? config.fields[splittedKey[0]][splittedKey[2]].uploadUrl : config.inputs[key].uploadUrl
                try {
                    const response = await requestApi({
                        method: 'POST',
                        url,
                        headers: { 'Content-Type': 'multipart/form-data' },
                        data: formData
                    })
                    console.log('submitFiles response: ', response)
                    if (isKeyFieldArray) {
                        data[splittedKey[0]][parseInt(splittedKey[1])][splittedKey[2]] = response.data
                    } else {
                        data[key] = response.data
                    }
                } catch (e) {
                    // return alert(e)
                    throw e
                }
            }
        }
    }

    const onSubmit = async data => {
        setLoading(true)
        console.log('===onSubmit data ori=== : ', JSON.parse(JSON.stringify(data)))
        console.log('===onSubmit data ori=== : ', inputFiles)

        console.log('onSubmit modifiedInputsRef.current: ', modifiedInputsRef.current)
        // if (!modifiedInputs.length) return alert('no input are modified.. submitting form cancel.')

        const modifiedData = {}
        modifiedInputsRef.current.map(change => {
            modifiedData[change] = data[change]
        })

        // include yg require untuk proses di api nya ga error require
        Object.keys(config.inputs).map(fieldName => {
            if (config.inputs[fieldName].rules && config.inputs[fieldName].rules.required) {
                modifiedData[fieldName] = data[fieldName]
            }
        })

        const errorInputs = formUtils.validateInputs(
            config.inputs,
            values.id ? modifiedData : data
        )

        const typeFileNames = formUtils.filterInputTypeNames(config, 'file')
        if (typeFileNames.length) {
            typeFileNames.map( fileInput => {
                if(config.inputs[fileInput]?.rules?.required && !inputFiles[fileInput] && !data[fileInput]) {
                    errorInputs.push({ name: fileInput, type: 'require', errorMsg: config.inputs[fileInput].rules.required})
                }
                return true
            })
        }

        console.log('onSubmit errorInputs: ', errorInputs)
        // return

        if (errorInputs.length) {
            errorInputs.map(errorInput =>
                setError(errorInput.name, { type: 'manual', message: errorInput.errorMsg || null })
            )
            setLoading(false)
        } else {
            // if (Object.keys(inputFiles).length > 0) {
            //     await submitFiles(values.id ? modifiedData : data)
            // }

            if (Object.keys(inputFiles).length > 0 && !onSubmitPress) {
                try {
                    await submitFiles(values.id ? modifiedData : data)
                } catch (e) {
                    if (typeof e === 'string') alert(e)
                    setLoading(false)
                    return
                }
            }            

            console.log('onSubmit data: ', data)
            // calculate config data
            Object.keys(config.inputs).map(fieldName => {
                if (config.inputs[fieldName].config) {
                    const newParam = {}

                    Object.keys(config.inputs[fieldName].config.inputs).map(
                        configFieldName => {
                            const mergedconfigFieldName = `${fieldName}_${configFieldName}`
                            if (
                                modifiedInputsRef.current.indexOf(mergedconfigFieldName) >
                                -1
                            ) {
                                // console.log('modifiedData[mergedconfigFieldName]: ', modifiedData[mergedconfigFieldName])
                                newParam[configFieldName] =
                                    modifiedData[mergedconfigFieldName]
                                delete modifiedData[mergedconfigFieldName]
                            }
                        }
                    )

                    if (Object.keys(newParam).length)
                        modifiedData[fieldName] = newParam
                }
            })

            executeSubmit(values.id ? modifiedData : data)
        }
    }

    const executeSubmit = async validatedData => {
        if (onSubmitPress) {
            setLoading(false)
            onSubmitPress(validatedData, inputFiles)
            return
        }

        const aErrors = []
        if (!values.id && !config.createUrl)
            aErrors.push('createUrl is not defined in config')
        if (values.id && !config.updateUrl)
            aErrors.push('updateUrl is not defined in config')

        if (aErrors.length) {
            alert(aErrors.join(', '))
            setLoading(false)
            return
        }

        let url = values.id
            ? `${config.endPoint}/update`
            : `${config.endPoint}/create` //deprecated

        if (!values.id && config.createUrl) url = `${config.createUrl}`
        if (values.id && config.updateUrl) url = `${config.updateUrl}`

        if (values.id) validatedData.id = values.id
        // else {
        //   console.log('onSubmitPress config.where: ', config.where)
        //   if (config.where && Object.keys(config.where).length > 0) validatedData = {...validatedData, ...config.where}
        // }

        if (config.where && Object.keys(config.where).length > 0)
            validatedData = { ...validatedData, ...config.where }

        try {
            if (config.calculateSubmitData)
                validatedData = config.calculateSubmitData(
                    validatedData,
                    values,
                    rootParams
                )
            validatedData.accessToken = accessToken
            const response = await requestApi({
                url,
                method: 'POST',
                data: validatedData
            })
            setLoading(false)
            if (onSubmitFinish) onSubmitFinish(null, response)
        } catch (error) {
            console.log('executeSubmit error: ', error)
            setLoading(false)
            if (onSubmitFinish) onSubmitFinish(error)
        }
    }

    useEffect(() => {
        console.log('useEffect inputFiles loaded')
        console.log('errors: ', errors)

        const typeFileNames = formUtils.filterInputTypeNames(config, 'file')
        if (typeFileNames.length) {
            typeFileNames.map( fileInput => {
                if(errors[fileInput] && inputFiles[fileInput]) {
                    clearErrors(fileInput)
                }
                return true
            })
        }

    }, [inputFiles])

    // const onSubmit_no_changes = async data => {
    //     const errorInputs = formUtils.validateInputs(config.inputs, data)
    //     console.log('onSubmit errorInputs: ', errorInputs)
    //     // return

    //     if (errorInputs.length) {
    //         errorInputs.map(errorInput =>
    //             setError(errorInput.field, { type: 'manual' })
    //         )
    //     } else {
    //         if (Object.keys(inputFiles).length > 0) {
    //             await submitFiles(data)
    //         }

    //         console.log('onSubmit data: ', data)
    //         // calculate config data
    //         Object.keys(config.inputs).map(fieldName => {
    //             if (config.inputs[fieldName].config) {
    //                 const newParam = {}

    //                 Object.keys(config.inputs[fieldName].config.inputs).map(
    //                     configFieldName => {
    //                         newParam[configFieldName] =
    //                             data[`${fieldName}_${configFieldName}`]
    //                         delete data[`${fieldName}_${configFieldName}`]
    //                     }
    //                 )

    //                 data[fieldName] = newParam
    //             }
    //         })

    //         onSubmitPress(data)
    //     }
    // }

    // const _handleReset = () => {
    //     reset(formUtils.calculateValues(config, values || {}))
    //     if (onResetPress) onResetPress()
    // }

    //   useEffect(() => {
    //     console.log('hideInput: ', hideInput)
    //     return () => {}
    //   }, [hideInput])

    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log('useEffect watch loaded')
            if (type === 'change') {
                calculateHideInput(name, value)

                // console.log('modifiedInputs: ', modifiedInputs)
                // console.log('name: ', name)

                // if (modifiedInputs.indexOf(name) === -1) {
                // setModifiedInputs(current => {
                //     console.log('setModifiedInputs current: ', current)

                //     if (current.indexOf(name) === -1) return [...current, name]
                //     else return [...current]
                // })
                // }

                
                if (modifiedInputsRef.current.indexOf(name) === -1) {
                    modifiedInputsRef.current = [...modifiedInputsRef.current, name]
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const initDefault = useCallback(() => {
        const tmpDefaultValues = formUtils.calculateValues(config, finalValues || {})
        const typeSelectNames = formUtils.filterInputTypeNames(config, 'select')
        if (typeSelectNames.length) {
            console.log('typeSelectNames: ', typeSelectNames)

            // mode edit init inputSelected
            // if (values.id) {
            const initSelected = {}
            typeSelectNames.map(name => {
                initSelected[name] = ''
                if (config.inputs[name].configs.renderSelected) {
                    initSelected[name] = config.inputs[
                        name
                    ].configs.renderSelected(tmpDefaultValues[name], config.inputs[name])
                } else {
                    // default standar
                    if (tmpDefaultValues[name] && tmpDefaultValues[name].id && tmpDefaultValues[name].name)
                        initSelected[name] = {
                            value: tmpDefaultValues[name].id,
                            label: tmpDefaultValues[name].name
                        }
                }
            })

            console.log('initSelected: ', initSelected)
            setInputSelected(initSelected)
            // }
        }

        console.log('tmpDefaultValues: ', tmpDefaultValues)
        reset(tmpDefaultValues)

        // init calculateHideInput
        Object.keys(tmpDefaultValues).map(fieldName => {
            calculateHideInput(fieldName, tmpDefaultValues)
        })
    }, [])

    useEffect(() => {

        if(firstLoad) return
        
        firstLoad.current = true
        initDefault()

    }, [values])

    const formProps = {
        config,
        rootParams,
        control,
        watch,
        isSubmitting,
        onCancelPress,
        errors,
        clearErrors,
        trigger,
        values,
        getValues,
        setValue,
        inputFiles,
        setInputFiles,
        inputSelected,
        setInputSelected,
        inputSelectOptions,
        setInputSelectOptions,
        loading,
        accessToken,
        accountMode
    }

    if (render) {
        return render(formProps)
    }    

    return (
        <>
            <ViewComponent
                FormLayoutComponent={FormLayoutComponent}
                onBtnSubmitPress={handleSubmit(onSubmit)}
                {...formProps}
            />
            {/* {FormLayoutComponent && <FormLayoutComponent {...formProps} />}
            {!FormLayoutComponent && <FormLayoutDefault onBtnSubmitPress={_onSubmitPress} {...formProps} />}         */}
        </>
    )
}

export default App
