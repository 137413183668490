import info from './info'
import navigation from './navigation'
// import theme from './theme'
// import language from './language'
import module from './module'
import store from './store'
// import tabs from './navigation/tabs'
import router from './routers'

const __DEV__ = true

export default {
    name: 'GoBogor',
    info,
    // tabs,
    navigation,
    scheme: 'light',
    // theme,
    // language,
    store,
    router,
    api: {
        url: `https://gobogor.id/api${__DEV__ ? '' : ''}`,
        key: 'PPBJLUXKeggU6RmP9jkpCqYpLv4FKSuU',
        secret: null
    },
    static: { url: 'https://static.dehills.id' },
    notification: {
        enable: __DEV__ ? false : true,
        channel: {
            channelId: 'gobogor-notification',
            channelName: 'GoBogor Notification',
            channelDescription: 'GoBogor notification channel',
            soundName: 'default'
        }
    },
    socket: {
        enable: true,
        url: 'https://gobogor.id',
        path: __DEV__ ? '/socket.io' : '/socket.io'
    },
    codePush: {
        enable: __DEV__ ? false : true,
        options: {
            checkFrequency: 1, // ON_APP_RESUME
            deploymentKey: '0TOZKcn142YDxPglJsCZlrQWaZdk0ApB3foH9', // Android Staging (default)
            // deploymentKey: 'xh3pxFDKHTE00RpW0-eZbifJ2_E7kA8ON4d0a', // Android Production
            // deploymentKey: 'C_BZTT2mQAbMAcTcP_yUoquy-00KTE7iaFk44', // iOS Staging (default)
            // deploymentKey: 'wZGFhCIROaw_9rKCzEm-1P-bQegb9pXQsEgx6', // iOS Production
            installMode: 2, // ON_NEXT_RESUME
            mandatoryInstallMode: 1, // ON_NEXT_RESTART
            minimumBackgroundDuration: 60 * 5 // 5 minutes
        }
    },
    adMob: {
        enable: false,
        appIds: {
            android: 'ca-app-pub-9387323949752538~9286387958',
            ios: 'ca-app-pub-9387323949752538~4381545637'
        },
        unitIds: {
            appOpen: null,
            banner: 'ca-app-pub-9387323949752538/9651977780',
            interstitial: null,
            rewarded: null
        }
    },
    trackPlayer: { enable: __DEV__ ? true : true, showOnTab:  __DEV__ ? false : false },
    module    
}
