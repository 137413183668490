import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import config from '../config'
import FormBuilder from 'components/Builder/form'
import FormLayoutComponent from './FormLayout'

import requestApi from "services/requestApi";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const App = ({ className = "", onCancelPress, submit, values }) => {
  const routerParams = useParams()
  const query = useQuery()

  const navigate = useNavigate()

  const [state, setState] = React.useState({ loading: true, event: {}, participantType: {}, calculatedInputs: {} })

  const getParticipantType = async ({ event }) => {
    const params = { sort: 'sortOrder asc', limit: 1 }

    let caluclateParam = { name: 'event', value: event.id }

    if (query.get('participantType')) {
      caluclateParam = { name: 'participantType', value: query.get('participantType') }
    }

    if (routerParams.participantType) { // slug participantType
      caluclateParam = { name: 'name', value: routerParams.participantType }
    }

    params[caluclateParam.name] = caluclateParam.value

    const response = await requestApi('/event/page/participant/types', { params })
    return response.data
  }  

  const getEventDetails = async () => {
    const params = { collect: 'account,host,organizer' }

    if(!Number.isInteger(routerParams.slug)) {
      params.slug = routerParams.slug
    } else {
      params.id = routerParams.slug
    }

    const response = await requestApi('/event/page/events', { params });

    if (response.data.length) {
      const event = response.data[0]
      const resParticipantType = await getParticipantType({ event })

      const calculatedInputs = {}
      if(resParticipantType.length) {
        if (resParticipantType[0].formFields && resParticipantType[0].formFields.length) {
          resParticipantType[0].formFields.forEach( field => {
            calculatedInputs[field.name] = { ...config.form.inputs[field.name], ...field }
          })
        }
      }

      setState( curr => ({ ...curr, loading: false, event, participantType: resParticipantType[0], calculatedInputs }))
    } else {
      setState( curr => ({ ...curr, loading: false }))
    }
  }

  const onSubmitFinish = (err, result) => {
    if(err) return alert(err)
    console.log('result: ', result)
    navigate(`/event/page/participant/profile?id=${result?.data?.id || null}&referrer=form`)
  }

  const handleCancel = () => {
    if (onCancelPress) onCancelPress()
    else navigate(`/event/${state.event.slug ?? state.event.id}`)
  }
  
  const onSubmitPress = (validatedData, inputFiles) => {
    console.log('onSubmitPress validatedData: ', validatedData)
    console.log('onSubmitPress inputFiles: ', inputFiles)

    if(inputFiles?.profilePicture?.length) {
        validatedData.profilePicture = URL.createObjectURL(inputFiles.profilePicture[0])
    }

    submit(JSON.parse(JSON.stringify(validatedData)), inputFiles)
    onCancelPress()
  }

  React.useEffect(() => {
    getEventDetails();
  }, []);

  const renderMain = () => {
    return (
        <div>
          {state.calculatedInputs ? (
            <FormBuilder
              config={{ ...config.form, inputs: state.calculatedInputs }} values={values ?? config.form.defaultValues(state.calculatedInputs)}
              accessToken={null}
              onSubmitPress={onSubmitPress}
              onCancelPress={handleCancel}
              FormLayoutComponent={FormLayoutComponent}
            />
          ) : null }
        </div>
    );
  }

  return (
    <div className={`${className}`}>
        {state.loading && (
          <div className="flex justify-center items-center">
            <div>loading...</div>
          </div>          
        )}
        {!state.loading && (
          <main className="container mb-4">
            <div className="w-full">{renderMain()}</div>
          </main>
        )}
    </div>
  )
}

export default App
