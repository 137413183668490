import { Controller } from 'react-hook-form'
import Input from 'shared/Input/Input'
import Label from 'components/Label/Label'

const App = ({ name, config, configInput, control }) => {

    // untuk kebutuhan yg useFieldArray
    if (!configInput.type) return null 
  
    return (
      <div className="space-y-1">
        <Label>{configInput.label}</Label>
        <Controller 
          control={control} name={name} rules={configInput?.rules}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
  
            const hasError = error !== undefined
            
            return (
                <>
                <Input
                  value={value} onChange={onChange}
                  // placeholder={config.inputs[name].placeholder}
                  // defaultValue={config.inputs[name].defaultsTo || ''}
                />
                {hasError && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {error.message}
                    </span>
                )}
                </>
            )
        }}/>                
      </div>     
    )
  }

  export default App