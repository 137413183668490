import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import moment from "moment";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import { useSelector } from "react-redux"
import Avatar from "shared/Avatar/Avatar";

import config from '../participant/config'
import FormBuilder from 'components/Builder/form'
import FormLayoutComponent from './FormLayout'
import { useNavigate } from "react-router-dom";

export interface CheckOutPageProps {
  className?: string;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const navigate = useNavigate()

  const event = useSelector((state: any) => state.event)

  const [rangeDates, setRangeDates] = useState<DateRage>({
    startDate: moment().add(1, "day"),
    endDate: moment().add(5, "days"),
  });
  const [guests, setGuests] = useState<GuestsObject>({
    guestAdults: 2,
    guestChildren: 1,
    guestInfants: 1,
  });

  const onSubmitFinish = (err: any, result: any) => {
    if(err) return alert(err)

    console.log('result: ', result)
    navigate(`/event/page/checkout/complete`)
    
  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              {/* <NcImage src="https://images.pexels.com/photos/6373478/pexels-photo-6373478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" /> */}
              <NcImage src={`${event.page.cart.profilePicture}`} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-base font-medium mt-1 block">
                {event.page.cart.name}
              </span>              
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {event.page.cart.locationName}
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {event.page.cart.locationAddress}
              </span>              
            </div>
            {/* <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
              2 beds · 2 baths
            </span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
            <StartRating /> */}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Waktu</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Tanggal</span>
            <span>{moment(event.page.cart.startDate).format('DD MMMM YYYY')}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Jam</span>
            <span>{moment(event.page.cart.startTime, 'HH:mm:ss').format('HH:mm')}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <span className="flex justify-between text-neutral-500 dark:text-neutral-400 items-center">
              <span>Diselenggarakan oleh</span>
              <span>
                <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" imgUrl={event.page.cart.account.profilePicture} />
                <span className="text-neutral-900 dark:text-neutral-200 font-medium ml-2">{event.page.cart.account.shortName}</span>
              </span>
            </span>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Pendaftaran
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold">{event.page.cart.name}</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Event details"
            />
          </div>
          {/* <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <ModalSelectDate
              defaultValue={rangeDates}
              onSelectDate={setRangeDates}
              renderChildren={({ openModal }) => (
                <button
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5 "
                  type="button"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Date</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      {converSelectedDateToString(rangeDates)}
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />

            <ModalSelectGuests
              defaultValue={guests}
              onChangeGuests={setGuests}
              renderChildren={({ openModal }) => (
                <button
                  type="button"
                  onClick={openModal}
                  className="text-left flex-1 p-5 flex justify-between space-x-5"
                >
                  <div className="flex flex-col">
                    <span className="text-sm text-neutral-400">Guests</span>
                    <span className="mt-1.5 text-lg font-semibold">
                      <span className="line-clamp-1">
                        {`${
                          (guests.guestAdults || 0) +
                          (guests.guestChildren || 0)
                        } Guests, ${guests.guestInfants || 0} Infants`}
                      </span>
                    </span>
                  </div>
                  <PencilSquareIcon className="w-6 h-6 text-neutral-6000 dark:text-neutral-400" />
                </button>
              )}
            />
          </div> */}
        </div>

        <div>
          {/* <h3 className="text-2xl font-semibold">Pay with</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div> */}

          <div className="">
                {/* <div className="space-y-5">
                  <div className="space-y-1">
                    <Label>Nama Lengkap</Label>
                    <Input type="text" defaultValue="" />
                  </div>
                  <div className="space-y-1">
                    <Label>Nama Instansi</Label>
                    <Input type="text" defaultValue="" />
                  </div>   
                  <div className="space-y-1">
                    <Label>Jabatan</Label>
                    <Input type="text" defaultValue="" />
                  </div>                                 
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" defaultValue="" />
                  </div>
                  <div className="space-y-1">
                    <Label>Nomor Handphone</Label>
                    <Input type="text" defaultValue="" />
                  </div>
                </div>
            <div className="pt-8">
              <ButtonPrimary href={"/event/page/checkout/complete"}>Kirim</ButtonPrimary>
            </div> */}

          <FormBuilder
            config={config.form} values={{}}
            accessToken={null}
            onSubmitFinish={onSubmitFinish}
            // onSubmitPress={onSubmitPress}
            FormLayoutComponent={FormLayoutComponent}
          />            
          </div>
        </div>
      </div>
    );
  };

  if (!event?.page?.cart?.id) {
    return (
      <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
        <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
          Acara belum dipilih.
        </main>
      </div>      
    )
  }
  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;
