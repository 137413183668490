import React, { FC } from "react";
import { AuthorType } from "data/types";
import { StarIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";

export interface CardAuthorBoxProps {
  className?: string;
  author: AuthorType;
  index?: number;
}

const CardAuthorBox: FC<CardAuthorBoxProps> = ({
  className = "",
  author,
  index,
}) => {
  const { displayName, href = "/", avatar, starRating, countFollowers = 0, countFollowing = 0 } = author;
  return (
    <Link
      to={href}
      className={`nc-CardAuthorBox text-inherit relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardAuthorBox"
    >
      {/* {index && ( <Badge className="absolute left-3 top-3" color={index === 1 ? "red" : index === 2 ? "blue" : "green"} name={`#${index}`} /> )} */}
      <Avatar sizeClass="w-20 h-20 text-2xl" radius="rounded-full" imgUrl={avatar} userName={displayName} />
      {/* <img className="w-20 h-20 text-2xl rounded-full" src={avatar} /> */}

      <div className="mt-3">
        <h2 className={`text-base font-medium`}>
          <span className="line-clamp-1">{displayName}</span>
        </h2>
        {/* <span
          className={`block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400`}
        >
          Puncak
        </span> */}
      </div>

      <div className="w-full text-center">
                <div className="flex justify-center lg:pt-2 pt-4 pb-0">
                    {/* <div className="p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-slate-700">3,360</span>
                        <span className="text-sm text-slate-400">Photos</span>
                    </div> */}
                    <div className="p-3 text-center">
                        <span className="font-bold block uppercase tracking-wide text-slate-700">{countFollowers}</span>
                        <span className="text-sm text-slate-400">Followers</span>
                    </div>

                    <div className="p-3 text-center">
                        <span className="font-bold block uppercase tracking-wide text-slate-700">{countFollowing}</span>
                        <span className="text-sm text-slate-400">Following</span>
                    </div>
                </div>
            </div>

        {/* <div className="py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
          <span className="text-xs font-medium pt-[1px]">
            {starRating || 4.9}
          </span>
          <StarIcon className="w-5 h-5 text-amber-500 ml-2 " />
        </div>      */}
          
    </Link>
  );
};

export default CardAuthorBox;
