import account from 'modules/account/store/slice'
// import location from '@services/geolocation/store/slice'
// import application from '@themes/felix/store/slice'

// import eventAddToCart from '@modules/event/cart/store/addToCart'
// import eventCart from '@modules/event/cart/store/slice'

// //
// import hotel from '@modules/hotel/portal/store/slice'

export default {
    reducer: {
        account,
        // location, application,
        // eventAddToCart, eventCart,
        // hotel
    }
}

