export default {
    // contact: {
    //     phone: '62811829839',
    //     whatsapp: '62811829839',
    //     email: 'info@dehills.id'
    // },
    // socialMediaLinks: {
    //     facebook: 'https://www.facebook.com/dehills.radio',
    //     instagram: 'https://www.instagram.com/dehills.radio',
    //     twitter: false
    // }

    contact: {
        phone: '628111856000',
        whatsapp: '628111856000',
        email: 'info@gobogor.id'
    },
    socialMediaLinks: {
        facebook: 'https://www.facebook.com/gobogor.official',
        instagram: 'https://www.instagram.com/gobogor.official',
        twitter: false
    }    
}
