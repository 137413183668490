import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import NcModal from "shared/NcModal/NcModal";
import NcImage from "shared/NcImage/NcImage";
import Avatar from "shared/Avatar/Avatar";

import config from '../config'
import FormBuilder from 'components/Builder/form'
import FormLayoutComponent from './FormLayout'

import requestApi from "services/requestApi";

export const inputsByParticipantType = {
  perusahaan: {
    profilePicture: config.form.inputs.profilePicture,
    fullName: { ...config.form.inputs.fullName, label: 'Nama Instansi', defaultsTo: 'testeste' },
    isOrganization: { ...config.form.inputs.isOrganization, defaultsTo: true }
  },
  panitia: {
    profilePicture: config.form.inputs.profilePicture,
    fullName: config.form.inputs.fullName,
    jobTitle: config.form.inputs.jobTitle,
    email: config.form.inputs.email,
    mobilePhone: config.form.inputs.mobilePhone,
  },
  peserta: {
    profilePicture: config.form.inputs.profilePicture,
    fullName: config.form.inputs.fullName,
    companyName: config.form.inputs.companyName,
    jobTitle: config.form.inputs.jobTitle,
    email: config.form.inputs.email,
    mobilePhone: config.form.inputs.mobilePhone,
  },
  'steering committee': {
    profilePicture: config.form.inputs.profilePicture,
    fullName: config.form.inputs.fullName,
    jobTitle: config.form.inputs.jobTitle,
    email: config.form.inputs.email,
    mobilePhone: config.form.inputs.mobilePhone,    
  },
  peninjau: {
    profilePicture: config.form.inputs.profilePicture,
    fullName: config.form.inputs.fullName,
    jobTitle: config.form.inputs.jobTitle,
    email: config.form.inputs.email,
    mobilePhone: config.form.inputs.mobilePhone,        
  },
  vendor: {
    profilePicture: config.form.inputs.profilePicture,
    fullName: config.form.inputs.fullName,
    companyName: config.form.inputs.companyName,
    email: config.form.inputs.email,
    mobilePhone: config.form.inputs.mobilePhone,        
  }  
}

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CheckOutPage = ({ className = "" }) => {
  const params = useParams();
  const query = useQuery();

  const participantType = query.get('type') || 'peserta'

  const _config = { ...config, form: { ...config.form, inputs: inputsByParticipantType[participantType]}}
  // console.log(_config.form.defaultValues)

  const navigate = useNavigate();

  const [profile, setProfile] = React.useState({ account: {} });

  const getEventDetails = async () => {
    const _params = { slug : params.slug, collect: 'account' };
    const response = await requestApi('/event/page/events', { params: _params });

    if (response.data.length) {
      const responseProfile = response.data[0]

      let images = []
      if (responseProfile.coverPicture) images.push(`${responseProfile.coverPicture}?size=1247x384`)

      if (responseProfile?.images?.length) {
        responseProfile.images.map( row => images.push(row.image));
      }

      const regex = /(<([^>]+)>)/ig;
      responseProfile.rawDescription = responseProfile.description.replace(regex, '');
      
      delete responseProfile.images;
      setProfile({ ...responseProfile });    
    }
  }

  const getFormInputs = async () => {
    const _params = {};
    const response = await requestApi('/event/page/participant/form/inputs', { params: _params });
    console.log('getFormInputs inputs: ', response)
  }  

  const onSubmitFinish = (err, result) => {
    if(err) return alert(err)
    console.log('result: ', result)
    navigate(`/event/page/participant/profile?id=${result?.data?.id || null}&referrer=form`)
  }

  const onCancelPress = () => {
    navigate(`/event/${profile.slug}`)
  }

  React.useEffect(() => {
    getEventDetails();
    getFormInputs()
  }, []);    

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={`${profile.profilePicture}`} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-base font-medium mt-1 block">
                {profile.name}
              </span>              
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {profile.locationName}
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {profile.locationAddress}
              </span>              
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Waktu</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Tanggal</span>
            <span>{moment(profile.startDate).format('DD MMMM YYYY')}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Jam</span>
            <span>{moment(profile.startTime, 'HH:mm:ss').format('HH:mm')} - {moment(profile.endTime, 'HH:mm:ss').format('HH:mm')}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <span className="flex flex-col justify-between sm:flex-row text-neutral-500 dark:text-neutral-400 items-center">
              <span>Diselenggarakan oleh</span>
              <span>
                <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" imgUrl={profile.account.profilePicture} />
                <span className="text-neutral-900 dark:text-neutral-200 font-medium ml-2">{profile.account.shortName}</span>
              </span>
            </span>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-2 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold capitalize">Pendaftaran {`${participantType}`}</h2>
        <h3 className="text-2xl font-semibold">{profile.name}</h3>
        <div>
            <NcModal
              renderTrigger={(openModal) => (
                <span onClick={() => openModal()} className="block lg:hidden underline  mt-1 cursor-pointer">
                  Lihat detil
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Info Acara"
            />
        </div>        
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          {/* {_config?.form?.inputs && ( */}
          <FormBuilder
            config={_config.form} values={_config.form.defaultValues(_config.form.inputs)}
            accessToken={null}
            onSubmitFinish={onSubmitFinish}
            onCancelPress={onCancelPress}
            FormLayoutComponent={FormLayoutComponent}
            rootParams={{ type: participantType, event: profile?.id || null }}
          />
          {/* )}             */}
        </div>
      </div>
    );
  };

  if (!inputsByParticipantType[participantType]) {
    return (
      <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
        <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
          Tipe partisipan tidak dikenal
        </main>
      </div>      
    )
  }

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;
