import CardAuthorBox from "modules/accommodation/hotel/components/CardAuthorBox/CardAuthorBox";
import CardAuthorBox2 from "modules/accommodation/hotel/components/CardAuthorBox2/CardAuthorBox2";
import Heading from "components/Heading/Heading";
import { DEMO_AUTHORS } from "data/authors";
import { AuthorType } from "data/types";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import requestApi from "services/requestApi";

export interface SectionGridAuthorBoxProps {
  className?: string;
  authors?: AuthorType[];
  boxCard?: "box1" | "box2";
  gridClassName?: string;
}

const DEMO_DATA = DEMO_AUTHORS.filter((_, i) => i < 10);

const parseUser = (data: any) => {
  data.displayName = `${data.firstName} ${data.lastName}`
  data.avatar = `${data.profilePicture}`
  data.href = `/profile/${data.id}`
}

const SectionGridAuthorBox: FC<SectionGridAuthorBoxProps> = ({
  className = "", authors = DEMO_DATA, boxCard = "box1",
  gridClassName = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ",
}) => {

  const [users, setUsers] = React.useState([])

  const getUsers = async ()=> {
    const params = {
      accountType: {'!=': 'organization'},
      // profilePicture: {'!=': ''},
      limit: 10,
      sort: 'createdAt desc'
    }
    const response = await requestApi('/account/publish', { params })
    console.log('getUsers response: ', response)

    response.data.map( (data: any) => {
      parseUser(data)
    })
    
    setUsers(response.data)
  }

  React.useEffect(() => {
    getUsers()
  }, [])

  return (
    <div className={`nc-SectionGridAuthorBox relative ${className}`} data-nc-id="SectionGridAuthorBox">
      <Heading desc="Mereka sudah membuktikan cinta nya ke Bogor. Kamu? gabung sekarang juga!" isCenter>
        Bogor Lovers
      </Heading>
      <div className={`grid gap-6 md:gap-8 ${gridClassName}`}>
        {users.map((author: any, index) =>
          boxCard === "box2" ? (
            <CardAuthorBox2 key={author.id} author={author} />
          ) : (
            <CardAuthorBox index={index < 3 ? index + 1 : undefined} key={author.id} author={author} />
          )
        )}
      </div>
      <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        <ButtonSecondary>Selengkapnya </ButtonSecondary>
        {/* <ButtonPrimary>Become a host</ButtonPrimary> */}
      </div>
    </div>
  );
};

export default SectionGridAuthorBox;
