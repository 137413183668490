import React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

import NcModal from "shared/NcModal/NcModal";
import NcImage from "shared/NcImage/NcImage";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import requestApi from "services/requestApi";
import ButtonSecondary from "shared/Button/ButtonSecondary";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const initState = { event: { account: {} }, account: {}, category: { formFields: [] }}

const App = ({ className = "" }) => {
  const query = useQuery();

  const [profile, setProfile] = React.useState({
    loading: true,
    data: JSON.parse(JSON.stringify(initState))
  });

  const getParticipantDetails = React.useCallback(async () => {
    const _params = { id : query.get('id'), collect: 'event,category' };
    const response = await requestApi('/event/page/participants', { params: _params });

    const data = response.data.length ? response.data[0] : JSON.parse(JSON.stringify(initState))
    console.log('data: ', data)
    setProfile({ loading: false, data })

  }, [])

  React.useEffect(() => {
    getParticipantDetails();
  }, []);

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={`${profile.data.event.profilePicture}`} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-base font-medium mt-1 block">
                {profile.data.event.name}
              </span>              
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {profile.data.event.locationName}
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {profile.data.event.locationAddress}
              </span>              
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Waktu</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Tanggal</span>
            <span>{moment(profile.data.event.startDate).format('DD MMMM YYYY')}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Jam</span>
            <span>{moment(profile.data.event.startTime, 'HH:mm:ss').format('HH:mm')} - {moment(profile.data.event.endTime, 'HH:mm:ss').format('HH:mm')}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <span className="flex flex-col justify-between sm:flex-row text-neutral-500 dark:text-neutral-400 items-center">
              <span>Diselenggarakan oleh</span>
              <span>
                <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" imgUrl={profile.data.event?.host?.profilePicture} />
                <span className="text-neutral-900 dark:text-neutral-200 font-medium ml-2">{profile.data.event?.host?.shortName}</span>
              </span>
            </span>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-2 px-0 sm:p-6 xl:p-8">
            <h3 className="text-2xl font-semibold">{profile.data.event.name}</h3>
            <div>
                <NcModal
                    renderTrigger={(openModal) => (
                        <span onClick={() => openModal()} className="block lg:hidden underline  mt-1 cursor-pointer">
                        Lihat detil
                        </span>
                    )}
                    renderContent={renderSidebar}
                    modalTitle="Info Acara"
                />
            </div>  

            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        
            <div className="space-y-6">
                <div className="flex flex-col space-y-4">
                    <div className="flex justify-center items-center" style={{ marginBottom: '1rem' }}>
                        <NcImage src={`https://gobogor.id/api/event/page/participant/profile/badge/view/image?id=${profile.data.id}`} />
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <h3 className="text-2xl font-semibold">Info Pendaftaran</h3>
                    <div className="flex text-neutral-6000 dark:text-neutral-300">
                        <span className="flex-1">Registration code</span>
                        <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {profile.data.id}
                        </span>
                    </div>    
                    <div className="flex text-neutral-6000 dark:text-neutral-300">
                        <span className="flex-1">Registration Date</span>
                        <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {moment(profile.data.createdAt).format('DD MMMM YYYY')}
                        </span>
                    </div>
                    <div className="flex text-neutral-6000 dark:text-neutral-300">
                        <span className="flex-1">Category</span>
                        <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100 capitalize">
                            {profile.data.category.name}
                        </span>
                    </div>            
                </div>
            </div>
            <div className="flex justify-center items-center" style={{ marginTop: '3rem'}}>
                <ButtonSecondary>
                  <a href={`https://gobogor.id/api/event/page/participant/profile/badge/view/html?id=${profile.data.id}`} target="_blank">Print</a>
                </ButtonSecondary>
            </div>          
            <div className="flex justify-center items-center" style={{ marginTop: '3rem'}}>
                <ButtonPrimary href={`/event/${profile.data.event.slug}`}>Kembali ke halaman acara</ButtonPrimary>
            </div>
        </div>
    );
  };

  if(!profile.loading && !profile.data.id) {

    return (
        <div className={`${className}`}>
          <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
            Participant tidak diketemukan
          </main>
        </div>      
      )
  }

  if(!profile.loading && profile?.data?.id) {
    return (
      <div className={`${className}`}>
        <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
          <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
          <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
        </main>
      </div>
    )
  } else {
    return (
        <div className={`${className}`}>
          <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
            Loading...
          </main>
        </div>      
    )
  }
}

export default App;
