import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

export const NAVIGATION: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    isNew: false,
  },
  {
    id: ncNanoId(),
    href: "/accommodation",
    name: "Akomodasi",
    isNew: false,
  },  
  {
    id: ncNanoId(),
    href: "/listing-experiences",
    name: "Wisata",
    isNew: false,
  }, 
  // {
  //   id: ncNanoId(),
  //   href: "/listing-car",
  //   name: "Transportasi",
  //   isNew: false,
  // },  
  {
    id: ncNanoId(),
    href: "/events",
    name: "Acara",
    isNew: false,
  }
];

export default NAVIGATION;
