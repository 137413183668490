import React from "react";

import { Provider as StoreProvider } from 'react-redux'
import MyRouter from "routers/index";

import store from 'store'
import SocketProvider from 'services/socket'

function App() {

  const {
    socket: { enable: socketEnabled }
  } = require('config').default


  const Container = () => (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>        
  )

  return (
    <StoreProvider store={store}>
        {socketEnabled ? (
            <SocketProvider>
                <Container />
            </SocketProvider>
        ) : (
            <Container />
        )}      

    </StoreProvider>
  );
}

export default App;
