import moment from "moment";
import React from "react";
import { Link, useParams } from "react-router-dom";
import requestApi from "services/requestApi";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcModal from "shared/NcModal/NcModal";
import NcImage from "shared/NcImage/NcImage";
import Avatar from "shared/Avatar/Avatar";
import CardItem from "./CardItem";
import Pagination from "./Pagination";


const App = ({ className = "" }) => {
  let routerParams = useParams();

  const [state, setState] = React.useState({
    loading: true,
    event: {},
    participants: { loading: true, data: [], metadata: {} }
  });

  const [pagination, setPagination] = React.useState(1)
  const [filter, setFilter] = React.useState('people')
  const [refresh, setRefresh] = React.useState(new Date())

  const paginate = (items, per) =>
  Array .from ({length: Math .ceil (items / per)}, (_, i) => ({
    page: i + 1,
    offset: i * per,
    range: [i * per + 1, Math .min ((i + 1) * per, items)],
    total: Math .min ((i + 1) * per, items) - (i * per)
  }))
  
  const handlePaginationChange = React.useCallback( num => {
    console.log('onPageClick num: ', num)
    setPagination(num)
    setRefresh(new Date())
  }, [])

  const handleFilter = React.useCallback( name => {
    setFilter(name)
    setPagination(1)
    setRefresh(new Date())
  }, [])

  const getParticipants = React.useCallback(async ({ event }) => {
    const limit = 30
    const params = { event: event.id, collect: 'organization,category', sort: 'createdAt desc', limit }
    if(pagination) params.skip = (pagination - 1) * limit

    let url = '/event/page/participant/people'
    if(filter === 'organization') url = '/event/page/participant/organizations'

    const response = await requestApi(url, { params })
    const { data, metadata }= response

    setState( curr => ({
      ...curr, loading: false,
      event,
      participants: {
        loading: false,
        data, metadata,
        paginate: paginate(metadata.numrows, metadata.limit)
      }
    }))
  }, [pagination, filter])  
  

  const getEventDetails = async () => {
    const params = { slug : routerParams.slug, collect: 'host,organizer,account' };
    const response = await requestApi('/event/page/events', { params });
    if (response.data.length) {
      const event = response.data[0]
      getParticipants({ event })
    } else {
      setState( curr => ({ ...curr, loading: false }))      
    }
  }

  React.useEffect(() => {
    getEventDetails()
  }, []);      

  React.useEffect(() => {
    if(!state.loading) {
      setState( curr => ({
        ...curr, participants: { ...curr.participants, loading: true, data: [] }
      }))      
      getParticipants({ event: state.event })
    }
  }, [refresh])

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={`${state.event.profilePicture}`} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-base font-medium mt-1 block">
                {state.event.name}
              </span>              
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {state.event.locationName}
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {state.event.locationAddress}
              </span>              
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Waktu</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Tanggal</span>
            <span>{moment(state.event.startDate).format('DD MMMM YYYY')}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Jam</span>
            <span>{moment(state.event.startTime, 'HH:mm:ss').format('HH:mm')} - {moment(state.event.endTime, 'HH:mm:ss').format('HH:mm')}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <span className="flex flex-col justify-between sm:flex-row text-neutral-500 dark:text-neutral-400 items-center">
              <span>Diselenggarakan oleh</span>
              <span>
                <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" imgUrl={state.event?.host?.profilePicture} />
                <span className="text-neutral-900 dark:text-neutral-200 font-medium ml-2">{state.event?.host?.shortName}</span>
              </span>
            </span>
        </div>
      </div>
    );
  };

  const renderFilter = (name) => {
    const translatedName = name === 'organization' ? 'instansi' : 'peserta'
    return (
      <div
        className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
          name === filter
            ? "border-primary-500 bg-primary-50 text-primary-700"
            : "border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={() => handleFilter(name) }
      >
        <span className="capitalize">{translatedName}</span>
      </div>
    );
  };  

  const renderMain = () => {
    return (
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 px-0 sm:p-6 xl:p-8">
            <h2 className="text-3xl font-semibold">Participants ({state.participants.metadata.numrows})</h2>
            <h3 className="font-semibold mb-6">
              <Link to={`/event/${state.event.slug ?? state.event.id}`}>{state.event.name}</Link>
            </h3>
            <NcModal
                renderTrigger={(openModal) => (
                    <span onClick={() => openModal()} className="block lg:hidden underline  mt-1 cursor-pointer">
                    Lihat detil
                    </span>
                )}
                renderContent={renderSidebar}
                modalTitle="Info Acara"
            />

            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

            <div className="mt-8">
              <div className="flex lg:space-x-4">
                <div className="hidden lg:flex space-x-4">
                  {renderFilter('people')}
                  {renderFilter('organization')}
                </div>
                <div className="flex lg:hidden space-x-4">
                  {renderFilter('people')}
                  {renderFilter('organization')}
                </div>
              </div>
            </div>

            <div className="flex mt-10 justify-center items-center">
              <Pagination
                defaultPage={Math.floor(state.participants.metadata.skip / state.participants.metadata.limit) + 1}
                page={Math.floor(state.participants.metadata.skip / state.participants.metadata.limit) + 1}
                count={Math.ceil(state.participants.metadata.numrows / state.participants.metadata.limit )}
                onChange={handlePaginationChange}                
              />
            </div>                      

            {state.participants.loading && (
              <div className="flex mt-16 mb-16 justify-center items-center">
                <div>loading...</div>
              </div>          
            )}            

            {!state.participants.loading && (
            <div className={`grid gap-4 md:gap-8 grid-cols-2 xl:grid-cols-3 mt-8`}>
                {state.participants.data.map((participant, index) => {
                    return (
                        <CardItem index={index < 3 ? index + 1 : undefined} key={participant.id} data={participant} filter={filter} />
                    )
                })}
            </div>
            )}

            <div className="flex mt-10 justify-center items-center">
              <Pagination
                defaultPage={Math.floor(state.participants.metadata.skip / state.participants.metadata.limit) + 1}
                page={Math.floor(state.participants.metadata.skip / state.participants.metadata.limit) + 1}
                count={Math.ceil(state.participants.metadata.numrows / state.participants.metadata.limit )}
                onChange={handlePaginationChange}                
              />
            </div>        

        </div>
    );
  };

  return (
    <div className={`${className}`}>
      {state.loading && (
          <div className="flex mt-16 mb-16 justify-center items-center">
            <div>loading...</div>
          </div>          
        )}
        {!state.loading && (
          <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
            <div className="hidden lg:block flex-grow">{renderSidebar()}</div>            
          </main>
        )}      
    </div>
  );
};

export default App;
