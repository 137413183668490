import moment from 'moment'

// import { stateToHTML } from 'draft-js-export-html'
// import { EditorState, ContentState, convertFromHTML } from 'draft-js'

const calculateFieldNames = (configInputs) => {
  const arrFieldNames = JSON.parse(JSON.stringify(configInputs))
  Object.keys(configInputs).map((name) => {
    if (configInputs[name].config) {
      Object.keys(configInputs[name].config.inputs).map((configInputName) => {
        arrFieldNames[`${name}_${configInputName}`] = configInputs[name].config.inputs[configInputName] 
      })
    }
  })

  return arrFieldNames
}

const validateInputs = (configInputs, modifiedInputs) => {
    // console.log('@utility validateInputs modifiedInputs: ', JSON.parse(JSON.stringify(modifiedInputs)))
    const calculatedFieldNames = calculateFieldNames(configInputs)
    console.log('@utility validateInputs calculatedFieldNames: ', calculatedFieldNames)

    const errorInputs = []
  
    for (const name of Object.keys(modifiedInputs)) {
      console.log('@utility builder validateInputs name: ', name)
      console.log('@utility builder validateInputs calculatedFieldNames[name]): ', calculatedFieldNames[name])
      if (!calculatedFieldNames[name]) {
        delete modifiedInputs[name] // sementara ini delete aja konsep nya selama field name gak terdaftar di model
        continue
      }

      switch (calculatedFieldNames[name].dataType) {

        case 'number':
        case 'string':
          if (calculatedFieldNames[name].require) {
            if (!modifiedInputs[name] || (modifiedInputs[name] && modifiedInputs[name].length === 0)) {
                errorInputs.push({name, type: 'require', errorMsg: configInputs[name].errorMsg.require})
            }
          }
        break
      }

      switch (calculatedFieldNames[name].type) {
        case 'select':
          if (!calculatedFieldNames[name].configs.isMulti) {
            if (typeof modifiedInputs[name] === 'object' && modifiedInputs[name]) modifiedInputs[name] = modifiedInputs[name].id || modifiedInputs[name].value
          }
        break

        // case 'editor':
        //   modifiedInputs[name] = stateToHTML(modifiedInputs[name].getCurrentContent())
        // break
        
        // bikin error pas submit
        // case 'date':
        // case 'datetime':
        //   if (inputs[field]) {
        //     const selectedValue = (typeof modifiedInputs[name] === 'object') ? modifiedInputs[name][0] : modifiedInputs[name] // berarti input nya ada di ubah
        //     if (!selectedValue) modifiedInputs[name] = ''
        //     else {
        //       if (selectedValue === '0000-00-00 00:00:00') modifiedInputs[name] = ''
        //       else {
        //         // modifiedInputs[field] = getDateIgnoreTimezone(moment(selectedValue).toDate()) //deprecated
        //         modifiedInputs[name] = moment(selectedValue).format('YYYY-MM-DD HH:mm:ss')
        //       }
        //     }
        //   }

        // break        
      }      
    }
  
    return errorInputs
}

const defaultValues = (configInputs) => {
  // console.log('@utility defaultValues configInputs: ', configInputs)
  const defaultValues = {}

  Object.keys(configInputs).map((name) => {
    if (typeof configInputs[name].defaultsTo !== 'undefined') defaultValues[name] = configInputs[name].defaultsTo
    if (typeof configInputs[name].defaultsTo === 'undefined') {
      if (configInputs[name].dataType === 'string') defaultValues[name] = ''
      if (configInputs[name].dataType === 'number') defaultValues[name] = 0
      if (configInputs[name].dataType === 'boolean') defaultValues[name] = false
    }
  })

  return defaultValues
}

const calculateValues = (config, values) => {
  const clonedValues = values ? JSON.parse(JSON.stringify(values)) : {}

  for (const key of Object.keys(clonedValues)) {
    if (config.inputs[key]) {
      // switch (config.inputs[key].type) {
      //   case 'editor':
      //     const blocksFromHTML = convertFromHTML(clonedValues[key])
      //     const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)

      //     clonedValues[key] = EditorState.createWithContent(state)
      //   break 
      // }

      if (config.inputs[key].config) {
        Object.keys(config.inputs[key].config.inputs).map((inputName, inputIdx) => {
          clonedValues[`${key}_${inputName}`] = clonedValues[key][inputName]
        })
      }

    }
  }

  return clonedValues
}

const filterInputTypeNames = (config, wantedInputType) => {
  const results = Object.keys(config.inputs).filter((name) => config.inputs[name].type === wantedInputType)
  
  return results
}

export default {
  defaultValues, validateInputs, calculateValues, filterInputTypeNames
}