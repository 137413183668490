import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useDispatch, useSelector } from "react-redux";

import { useGoogleLogin } from '@react-oauth/google';
import requestApi from "services/requestApi";
import { logIn } from 'modules/account/store/slice'
import { setItems, setItem } from "services/storage";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    id: "google",
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const parseData = ({ data, accessToken }: any) => {
  const hasLastName = data.lastName !== ''
  const first = hasLastName ? data.firstName : data.firstName.split(' ')[0]
  const last = hasLastName ? data.lastName : data.firstName.split(' ')[1]
  return {
      data: {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          profilePicture: data.profilePicture,
          name: { full: `${first} ${last}`, first, last },
          avatar: data.profilePicture,
          newSignup: data.newSignup,
          isAdmin: data.isAdmin,
          accounts: data.accounts,
          provider: data.signupWith
      },
      accessToken
  }
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { account }: any = useSelector(state => state)
  const { module: { account: { auth } }, api: { url: apiUrl }} = require('config').default

  const onLoginSuccess = async (response: any) => {
    console.log('onLoginSuccess response: ', response)

    const { accessToken, data } = response
    const profile = JSON.parse(JSON.stringify(data))
    delete profile.accounts
    const accounts = data.accounts ? data.accounts : {loading: false, data: [], count: 0}

    await setItem('@accessToken', accessToken)
    await setItem('@accountProfile', JSON.stringify(profile))
    await setItem('@accounts', JSON.stringify(accounts))
    
    dispatch(logIn({ accessToken, profile, accounts }))
}  

  const loginGoogle = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const authResponse = await requestApi(apiUrl + '/auth/login/google', { params: { access_token: tokenResponse.access_token }})
      onLoginSuccess(parseData(authResponse))
    },
  })

  const signInWithlogin = (selected: any) => {
    if(selected.id !== 'google') {
      alert('fitur ini masih dalam pengembangan')
      return
    }

    loginGoogle()
  }

  React.useEffect(() => {
    console.log('account.isLoggedIn: ', account.isLoggedIn)
    if (account.isLoggedIn) return navigate('/account')
  }, [account.isLoggedIn])  

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Bogor Dalam Genggaman</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {auth.providers.map((item: any, index: any) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                onClick={() => signInWithlogin(item)}
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}

          {!auth.noForm && (
              <>
<div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>

          <form className="grid grid-cols-1 gap-6" action="#" method="post">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>
              <Input type="password" className="mt-1" />
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>              
              </>
          )}
{/* 
           */}

        </div>
      </div>
    </div>
  );
};

export default PageLogin;
