export const capitalize = (str, all = true) =>
    all
        ? str.replace(
              /\w\S*/g,
              txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          )
        : str.charAt(0).toUpperCase() + str.slice(1)

const underscored = str =>
    str
        .trim()
        .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
        .replace(/[-\s]+/g, '_')
        .toLowerCase()

export const humanize = (str, all) =>
    capitalize(underscored(str).replace(/_id$/, '').replace(/_/g, ' '), all)

export const sortBy = (field, reverse, primer) => {
    const key = x => {
        return primer ? primer(x[field]) : x[field]
    }
    return (a, b) => {
        const A = key(a),
            B = key(b)
        return (A < B ? -1 : A > B ? 1 : 0) * [-1, 1][+!!reverse]
    }
}

export const numberComma = (num, saperator) =>
    Math.round(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, saperator || ',')

export const UUID = () => {
    const s = n => h((Math.random() * (1 << (n << 2))) ^ Date.now()).slice(-n)
    const h = n => (n | 0).toString(16)
    return [
        s(4) + s(4),
        s(4),
        '4' + s(3),
        h(8 | (Math.random() * 4)) + s(3),
        Date.now().toString(16).slice(-10) + s(2)
    ].join('-')
}

export const truncate = (str, maxLength = 40) => {
    if (!str) return null
    if (str.length <= maxLength) return str
    return `${str.substring(0, maxLength)}...`
}

export const rupiah = (num, options = {}) =>
    new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        ...options
    })
        .format(num)
        .replace(/^(\D+)/, 'Rp')

export const hexToRgba = (hex, opacity) => {
    opacity = opacity || 1
    const rgb = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i ,(m, r, g, b) => '#' + r + r + g + g + b + b)
                .substring(1).match(/.{2}/g)
                .map(x => parseInt(x, 16))
    return `rgba(${rgb.join(',')}, ${opacity})`
}