import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { logOut } from 'modules/account/store/slice'
import { useNavigate } from "react-router-dom"

const App = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { account } = useSelector(state => state)
    const { isLoggedIn, profile, accounts } = account    

    const doLogOut = React.useCallback(() => {
        const provider = profile?.provider ?? undefined
        dispatch(logOut())

        // const onPress = () =>
        //     provider && provider === 'google'
        //         ? GoogleSignin.signOut().finally(() => dispatch(logOut()))
        //         : dispatch(logOut())
        // Alert.alert(
        //     'Logout',
        //     'Are you sure you want to log out?',
        //     [
        //         { text: 'Cancel', style: 'cancel' },
        //         { text: 'Yes', onPress }
        //     ],
        //     { cancelable: false }
        // )

    }, [dispatch, profile])

    React.useEffect(() => {
        if (account.isLoggedIn) return doLogOut()
        if (!account.isLoggedIn) return navigate('/login')
    }, [account.isLoggedIn])

    return (
        <></>
    )
}

export default App