import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import NcModal from "shared/NcModal/NcModal";
import NcImage from "shared/NcImage/NcImage";
import Avatar from "shared/Avatar/Avatar";

import config from '../config'
import FormBuilder from 'components/Builder/form'
import FormLayoutComponent from './FormLayout'
import FormGroupLayoutComponent from './FormGroupLayout'

import requestApi from "services/requestApi";
import { useForm } from "react-hook-form";

let renderCount = 0;
const path = `modules/event/page/registration/form/index`

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const App = ({ className = "", onCancelPress }) => {
  renderCount++
  console.log(`${path} renderCount: `, renderCount)

  const routerParams = useParams()
  const query = useQuery()
  const navigate = useNavigate()

  const [state, setState] = React.useState({ loading: true, event: {}, category: {}, calculatedInputs: {} })

  // const { register, control, handleSubmit, reset, watch } = useForm({
  //   defaultValues: {
  //     test: [{ firstName: "Bill", lastName: "Luo" }]
  //   }
  // })

  const getParticipantCategory = async ({ event }) => {
    const params = { sort: 'sortOrder asc', limit: 1 }

    let caluclateParam = { name: 'event', value: event.id }

    if (query.get('category')) {
      caluclateParam = { name: 'category', value: query.get('category') }
    }

    if (routerParams.category) { // slug category
      caluclateParam = { name: 'name', value: routerParams.category }
    }

    params[caluclateParam.name] = caluclateParam.value

    const response = await requestApi('/event/page/participant/categories', { params })
    return response.data
  }  

  const getEventDetails = async () => {
    const params = { collect: 'account,host,organizer' }

    if(!Number.isInteger(routerParams.slug)) {
      params.slug = routerParams.slug
    } else {
      params.id = routerParams.slug
    }

    const response = await requestApi('/event/page/events', { params });

    if (response.data.length) {
      const event = response.data[0]
      const resParticipantCategory = await getParticipantCategory({ event })

      const calculatedInputs = {}
      if(resParticipantCategory.length) {
        if (resParticipantCategory[0].formFields && resParticipantCategory[0].formFields.length) {
          resParticipantCategory[0].formFields.forEach( field => {
            calculatedInputs[field.name] = { ...config.form.inputs[field.name], ...field }
          })
        }
      }

      setState( curr => ({ ...curr, loading: false, event, category: resParticipantCategory[0], calculatedInputs }))
    } else {
      setState( curr => ({ ...curr, loading: false }))
    }
  }

  const onSubmitPress = async (data, inputFiles) => {
    console.log('onSubmitPress data: ', data)
    console.log('onSubmitPress inputFiles: ', inputFiles)

    alert('onSubmitPress')
  }  

  const onSubmitFinish = (err, result) => {
    if(err) return alert(err)
    console.log('result: ', result)
    navigate(`/event/page/participant/profile?id=${result?.data?.id || null}&referrer=form`)
  }

  const handleCancel = () => {
    if (onCancelPress) onCancelPress()
    else navigate(`/event/${state.event.slug ?? state.event.id}`)
  }  

  React.useEffect(() => {
    getEventDetails();
  }, []);    

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
              <NcImage src={`${state.event.profilePicture}`} />
            </div>
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <div>
              <span className="text-base font-medium mt-1 block">
                {state.event.name}
              </span>              
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {state.event.locationName}
              </span>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                {state.event.locationAddress}
              </span>              
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Waktu</h3>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Tanggal</span>
            <span>{moment(state.event.startDate).format('DD MMMM YYYY')}</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Jam</span>
            <span>{moment(state.event.startTime, 'HH:mm:ss').format('HH:mm')} - {moment(state.event.endTime, 'HH:mm:ss').format('HH:mm')}</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <span className="flex flex-col justify-between sm:flex-row text-neutral-500 dark:text-neutral-400 items-center">
              <span>Diselenggarakan oleh</span>
              <span>
                <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" imgUrl={state.event.host.profilePicture} />
                <span className="text-neutral-900 dark:text-neutral-200 font-medium ml-2">{state.event.host.shortName}</span>
              </span>
            </span>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-2 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold capitalize">Pendaftaran {`${state.category.name}`}</h2>
        <h3 className="text-2xl font-semibold">{state.event.name}</h3>
        <div>
            <NcModal
              renderTrigger={(openModal) => (
                <span onClick={() => openModal()} className="block lg:hidden underline  mt-1 cursor-pointer">
                  Lihat detil
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Info Acara"
            />
        </div>        
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* <span className="counter">Render Count: {renderCount}</span> */}
        <div>
          {state.calculatedInputs ? (
            <FormBuilder
              config={{ ...config.form, inputs: { ...state.calculatedInputs, contacts: [] } }} values={{ ...config.form.defaultValues(state.calculatedInputs), contacts: [] }}
              accessToken={null}
              // onSubmitPress={onSubmitPress}
              onSubmitFinish={onSubmitFinish}
              onCancelPress={handleCancel}
              // render={(props) => {
              
              //   return (
              //     <>
              //     {query.get('group') ? <FormGroupLayoutComponent {...props} /> : <FormLayoutComponent {...props} />}
              //     </>
              //   )
              // }}
              FormLayoutComponent={query.get('group') ? FormGroupLayoutComponent : FormLayoutComponent }
              rootParams={{ type: state.category.id, event: state.event?.id || null }}
            />
          ) : null }
        </div>
      </div>
    );
  }

  return (
    <div className={`${className}`}>
        {state.loading && (
          <div className="flex mt-16 mb-16 justify-center items-center">
            <div>loading...</div>
          </div>          
        )}
        {!state.loading && (
          <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
            <div className="hidden lg:block flex-grow">{renderSidebar()}</div>            
          </main>
        )}
    </div>
  )
}

export default App
