import React from 'react'

import * as Input from './inputTypes'

const App = props => {
    const { config, name, values, control, inputFiles, setInputFiles } = props

    const input = config.inputs[name]
    if (input.configs && input.configs.renderValue) {
        values[name] = input.configs.renderValue(values, name)
    }

    // if (input.type === 'text')
    //     return <Input.Text name={name} control={control} input={input} />

    // if (input.type === 'htmlEditor')
    //     return <Input.HtmlEditor name={name} control={control} input={input} />  

    // if (input.type === 'select')
    //     return <Input.Select {...{ input, ...props }} />

    // if (input.type === 'switch')
    //     return <Input.Switch name={name} control={control} input={input} />

    //  if (input.type === 'radio')
    //     return <Input.Radio name={name} control={control} input={input} />        

    // if (input.type === 'file')
    //     return (
    //         <Input.Image
    //             name={name}
    //             control={control}
    //             input={input}
    //             inputFiles={inputFiles[name] ? inputFiles[name] : []}
    //             setInputFiles={setInputFiles}
    //         />
    //     )
    
    // if (input.type === 'datetime')
    //     return <Input.DateTime name={name} control={control} input={input} />
    
    // if (input.type === 'map')
    //     return <Input.Map name={name} control={control} input={input} />
    
    // if (input.type === 'currency')
    //     return <Input.Currency name={name} control={control} input={input} />
    // return null
}

export default App