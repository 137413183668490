import React, { FC, Fragment, useState } from "react";

import { Tabs } from 'antd-mobile';
import { useThrottleFn } from 'ahooks';
import { animateScroll as scroll, scroller } from 'react-scroll';

export interface TabProps {
  className?: string;
  isPreviewMode?: boolean;
}
const tabItems = [
  { key: 'General Info', title: 'Info Kamar' },
  { key: 'Amenities', title: 'Fasilitas'},
  // { key: 'Reviews', title: 'Ulasan' },
  // { key: 'Location', title: 'Lokasi' },
  { key: 'Things to know', title: 'Kebijakan Kamar' },
  { key: 'Stay information', title: 'Deskripsi Kamar' }
];

const tabHeight = 42;
const tabContentOffset = -150;

const App: FC<TabProps> = ({
  className = "",
  isPreviewMode,
}) => {
  
  const [activeKey, setActiveKey] = React.useState('General Info');

  const { run: handleScroll } = useThrottleFn(
    () => {
      // console.log('handleScroll')
      let currentKey = tabItems[0].key;
      for (const item of tabItems) {
        const element = document.getElementById(`${item.key}`);
        if (!element) continue;
        const rect = element.getBoundingClientRect();
        if ((rect.top + tabContentOffset) <= tabHeight) {
          currentKey = item.key;
        } else {
          break;
        }
      }
      setActiveKey(currentKey);
    },
    {
      leading: true,
      trailing: true,
      wait: 100,
    }
  )

  const test = () => {
    var tabs = document.getElementsByClassName('Tab');

    Array.prototype.forEach.call(tabs, function(tab) {
      tab.addEventListener('click', setActiveClass);
    });
    
    function setActiveClass(evt: any) {
      Array.prototype.forEach.call(tabs, function(tab) {
        tab.classList.remove('active');
      });
      
      evt.currentTarget.classList.add('active');
    }    
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // test()
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const tailwindTab = () => {

    return (
      <>
          <div>
              {/* tailwind css */}
              {/* drafft nih bagus */}
              {/* https://redpixelthemes.com/blog/tailwindcss-hide-scrollbar/ */}
  
                    {/* <div className="relative sm:overflow-hidden">
                      <div className="border-b border-gray-200 dark:border-b dark:border-neutral-700">
                        <nav className="-mb-px flex space-x-8 overflow-x-auto scrollbar-hide">
                            <div className="whitespace-nowrap cursor-pointer flex py-4 px-1 border-b-2 border-transparent text-primary active" onClick={key => {
                            return scroll.scrollToTop()
                        }}>
                              Tab Name
                              <span className="bg-gray-100 text-gray-900 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">0</span>
                            </div>
  
                            <div className="whitespace-nowrap cursor-pointer flex py-4 px-1 border-b-2 border-transparent text-primary hover:text-gray-700 hover:border-primary" onClick={key => {
                            return scroll.scrollToTop()
                        }}>
                              Tab Name
                              <span className="bg-gray-100 text-gray-900 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">0</span>
                            </div>                              
                        </nav>
                      </div>
                  </div> */}


{/* <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
    <ul className="flex flex-wrap -mb-px">
        <li className="mr-2">
            <a href="#" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">Profile</a>
        </li>
        <li className="mr-2">
            <a href="#" className="inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500" aria-current="page">Dashboard</a>
        </li>
        <li className="mr-2">
            <a href="#" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">Settings</a>
        </li>
        <li className="mr-2">
            <a href="#" className="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300">Contacts</a>
        </li>
        <li>
            <a className="inline-block p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500">Disabled</a>
        </li>
    </ul>
</div> */}

          </div>
      </>    
    );
  };  
  
  const antTab = () => {

    return (
      <>
          <div>
            <Tabs
              // defaultActiveKey={activeKey}
              activeKey={activeKey}
              onChange={key => {
                if (key === 'General Info') return scroll.scrollToTop()
                scroller.scrollTo(`${key}`, { smooth: true, offset: tabContentOffset })
              }}
            >
              {tabItems.map(item => (
                <Tabs.Tab title={item.title} key={item.key}></Tabs.Tab>
              ))}
            </Tabs>
            
            {/* ini versi mentah yg tabs ant mobile diatas. perlu di optimasi lg karena theme nya jd kebawa dia */}
              {/* <div className="adm-tabs">
                <div className="adm-tabs-header">
                    <div className="adm-tabs-header-mask adm-tabs-header-mask-left" style={{opacity: 0}}></div>
                    <div className="adm-tabs-header-mask adm-tabs-header-mask-right" style={{opacity: 0}}></div>
                    <div className="adm-tabs-tab-list">
                      <div className="adm-tabs-tab-line" style={{width: 103, transform: 'translate3d(52px, 0px, 0px)'}}></div>
                      <div className="adm-tabs-tab-wrapper adm-tabs-tab-wrapper-stretch">
                        <div className="adm-tabs-tab adm-tabs-tab-active">General Info</div>
                      </div>
  
                      <div className="adm-tabs-tab-wrapper adm-tabs-tab-wrapper-stretch">
                        <div className="adm-tabs-tab">Room Rates</div>
                      </div>
  
                      <div className="adm-tabs-tab-wrapper adm-tabs-tab-wrapper-stretch">
                        <div className="adm-tabs-tab">Reviews</div>
                      </div>
  
                      <div className="adm-tabs-tab-wrapper adm-tabs-tab-wrapper-stretch">
                        <div className="adm-tabs-tab">Location</div>
                      </div>
  
                      <div className="adm-tabs-tab-wrapper adm-tabs-tab-wrapper-stretch">
                        <div className="adm-tabs-tab">Things to know</div>
                      </div>
                    </div>      
                </div>
              </div> */}
          </div>
      </>    
    );
  };

  return (
    <>
          {antTab()}
          {/* {tailwindTab()}     */}
    </>
  );
};

export default App;
