import EventPortalHome from "./portal/home";
import NusantaraBernyanyi from "./nusantarabernyanyi";
import EventPageProfile from "./page/profile";
import EventPageRegistrationForm from "./page/registration/form";
import EventPageGroupRegistrationForm from "./page/group/registration/form";
import EventPageParticipantList from "./page/participant/list";
import EventPageParticipantForm from "./page/participant/form";
import EventPageParticipantProfile from "./page/participant/profile";
import EventPageParticipantProfileBadgeView from "./page/participant/profile/badge/view";
import EventPageCheckout from "./page/checkout";
import EventPageCheckoutComplete from "./page/checkout/complete";

const router = [
  { path: "/events", exact: true, component: EventPortalHome },
  { path: "/nusantarabernyanyi", exact: true, component: NusantaraBernyanyi },
  { path: "/event/:slug", exact: true, component: EventPageProfile },
  
  // registration
  { path: "/event/:slug/registration/form", exact: true, component: EventPageRegistrationForm },
  { path: "/event/:slug/registration/form/:participantType", component: EventPageRegistrationForm },
  { path: "/event/:slug/group/registration/form", exact: true, component: EventPageGroupRegistrationForm },
  { path: "/event/:slug/group/registration/form/:participantType", component: EventPageGroupRegistrationForm },  

  { path: "/event/:slug/participants", exact: true, component: EventPageParticipantList },
  
  // deprecated, untuk halaman public mesti proses registrasi
  { path: "/event/:slug/participant/form", exact: true, component: EventPageParticipantForm },
  
  { path: "/event/page/participant/profile", exact: true, component: EventPageParticipantProfile },
  { path: "/event/page/participant/profile/badge/view", exact: true, component: EventPageParticipantProfileBadgeView },
  { path: "/event/page/checkout", exact: true, component: EventPageCheckout },
  { path: "/event/page/checkout/complete", exact: true, component: EventPageCheckoutComplete },
]

export default router;
