export default {
    news: {
        type: 'default',
        url: ''
    },
    organization: {
        menuStyle: 'default',
        allowToCreate: true,
        allowMultipleAccount: true,
        typeOptions: [
            // { label: 'Penginapan', value: 'lodging' },
            // { label: 'Makanan & Minuman', value: 'food' },
            { label: 'Hotel', value: 'hotel' },
            { label: 'Restoran', value: 'restaurant' },
            { label: 'Cafe', value: 'cafe' },
            { label: 'Wisata', value: 'point_of_interest' },
            { label: 'Coworking Space', value: 'space_coworking' },
        ]
    },
    dev: {
        userIds: [1]
    },
    voip: {
        hotline: '15300'
    }        
}
