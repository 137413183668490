import googleSvg from "images/Google.svg";

export default {
    contact: {
        socials: [
            { name: "Facebook", icon: "lab la-facebook-square", href: "https://fb.me/gobogor.official" },
            // { name: "Twitter", icon: "lab la-twitter", href: "#" },
            { name: "Youtube", icon: "lab la-youtube", href: "https://youtube.com/@gobogor.official" },
            { name: "Instagram", icon: "lab la-instagram", href: "https://instagram.com/gobogor.official" },
        ]
    },
    account: {
        auth: {
            providers: [
                // { name: "Continue with Facebook", href: "#", icon: facebookSvg },
                // { name: "Continue with Twitter", href: "#", icon: twitterSvg },
                { id: "google", name: "Continue with Google", href: "#", icon: googleSvg }
            ],
            noForm: true
        }
    },
    news: {
        type: 'default',
        url: ''
    },
    organization: {
        menuStyle: 'default',
        allowToCreate: true,
        allowMultipleAccount: true,
        typeOptions: [
            // { label: 'Penginapan', value: 'lodging' },
            // { label: 'Makanan & Minuman', value: 'food' },
            { label: 'Hotel', value: 'hotel' },
            { label: 'Restoran', value: 'restaurant' },
            { label: 'Cafe', value: 'cafe' },
            { label: 'Wisata', value: 'point_of_interest' },
            { label: 'Coworking Space', value: 'space_coworking' },
        ]
    },
    dev: {
        userIds: [1]
    },
    voip: {
        hotline: '15300'
    }        
}
