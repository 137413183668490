import React from "react";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";

const App = ({ data, filter }) => {
  let description = data.companyName || data.jobTitle
  if(data?.organization?.id) description = data.organization.fullName

  return (
    <div
      className={`text-inherit relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-4 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]`}
    >
      {/* <Badge className="absolute top-3" name={`${data.category.name}`}
        // color={index === 1 ? "red" : index === 2 ? "blue" : "green"}        
      /> */}

      <Avatar sizeClass="w-28 h-28 text-2xl" radius="rounded-full" imgUrl={`${data?.profilePicture ?? null }?size=300x300`} userName={data.fullName} />
      <Badge className="mt-3" name={`${data.category.name}`} />

      <div className="mt-3">
        <h2 className={`text-base font-medium`}>
          <span>{data.fullName}</span>
        </h2>
        {filter === 'people' && (
          <span className={`block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400`}>{description}</span>
        )}
      </div>
    </div>
  );
};

export default App;
