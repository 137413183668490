import HotelPortalHome from "./home";
import HotelPortalAccountDetail from "./account/profile";
import HotelPortalProductDetail from "./product/profile";

const router = [
  { path: "/accommodation", exact: true, component: HotelPortalHome },
  { path: "/accommodation/:account", exact: false, component: HotelPortalAccountDetail },
  { path: "/accommodation/:account/:product", exact: false, component: HotelPortalProductDetail }
]

export default router;
