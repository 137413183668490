import React, { FC } from "react";
import { StayDataType } from "data/types";
import TabFilters from "./TabFilters";
import PropertyCardH from "./PropertyCardH";
import { useParams } from "react-router-dom";
import requestApi from "services/requestApi";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const SectionGridFilterCard = ({
  className = "",
  accountData = null,
}) => {

  let params = useParams();
  const splittedParamAccount = params.account ? params.account.split('-') : [];
  const accountId = splittedParamAccount[splittedParamAccount.length - 1];

  const splittedParamProduct = params.product ? params.product.split('-') : [];
  const productId = splittedParamProduct[splittedParamProduct.length - 1];    

  const [products, setProducts] = React.useState<any>({ data: [], metadata: {} });
  const [photos, setPhotos] = React.useState<any>({});

  const getPropertyDetails = async () => {
      const params = { account: accountId, id: { '!=': productId }, collect: 'account,productCategory' };
      const response: any = await requestApi('/property/sales/products', { params });
      response.data.map((row: any) => {
        // row.images = row.images.unshift(row.profilePicture)
        row.galleryImgs = row.images.map((image: any) => image.image)
        row.galleryImgs.unshift(row.profilePicture)
        setProducts({ ...response });    
      })
  }

  React.useEffect(() => {
    getPropertyDetails();
  }, [params.product]);

  return (
    // <div className="listingSection__wrap" id={`Room Rates`}>
    <div id={`Room Rates`} className={`nc-SectionGridFilterCard ${className}`} data-nc-id="SectionGridFilterCard">
        <div>
          <h2 className="text-2xl font-semibold">Kamar Lainnya</h2>
        </div>

      {/* <div className="mb-8 lg:mb-11"> */}
        {/* <TabFilters /> */}
      {/* </div> */}

      {/* <div className="z-20 sticky bg-white dark:bg-neutral-900" style={{ top: '8rem' }}>
      <div className="py-3">
        <TabFilters />
      </div>
        </div> */}

      <div className="grid grid-cols-1 gap-6 md:gap-8">
        {products.data.map((product: any) => {
          console.log('products.data.map --- product: ', product)
          product.title = product.name
          product.href = `/accommodation/${params.account}/${product.name.split(' ').join('-')}-${product.id}`

          return (
          <PropertyCardH key={product.id} data={product}/>
          )
        })}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
    // </div>
  );
};

export default SectionGridFilterCard;
