// https://tw-elements.com/docs/standard/components/timeline/

const rundowns = [
    {
        startTime: '08.00', endTime: '08.40', name: 'Registrasi Peserta dan Coffee Morning', subs: []
    },
    {
        startTime: '08.40', endTime: '08.45', name: 'Acara Pembukaan', subs: [
            { startTime: '08.45', endTime: '09.00', name: 'Welcoming Traditional Dance' },
            { startTime: '09.00', endTime: '09.10', name: 'Pembacaan Do\'a' },
            { startTime: '09.10', endTime: '09.10', name: 'Menyanyikan Lagu Indonesia Raya' },
            { startTime: '09.10', endTime: '09.20', name: 'Menyanyikan Mars PHRI' },
            { startTime: '09.20', endTime: '09.30', name: 'Menyanyikan Mars Tegar Beriman' },
            { startTime: '09.30', endTime: '09.40', name: 'Laporan Panitia Penyelenggara' },
        ]
    },
    {
        startTime: '09.40', endTime: '09.45', name: 'Sambutan', subs: [
            { startTime: '09.45', endTime: '10.00', name: 'BPC PHRI Kab. Bogor' },
            { startTime: '10.00', endTime: '10.20', name: 'Bupati Bogor sekaligus meresmikan dibukanya Rakercab I PHRI' },
            { startTime: '10.20', endTime: '10.25', name: 'Pemberian Cinderamata' },
            { startTime: '10.25', endTime: '10.45', name: 'Presentasi dari WIFIKU' },
            { startTime: '10.45', endTime: '11.00', name: 'Presentasi Red Doorz' },
            { startTime: '11.00', endTime: '11.15', name: 'Presentasi dari Alesta PT.' },
            { startTime: '11.15', endTime: '11.30', name: 'Presentasi Leoni' },
        ]
    },
    {
        startTime: '11.30', endTime: '13.00 ', name: 'Ishoma - Ramah Tamah', subs: []
    },
    {
        startTime: '', endTime: '', name: 'Acara Persidangan', subs: [
            { startTime: '13.00', endTime: '13.45', name: 'Pleno I - Pengesahan Jadwal Acara Dan TaTib' },
            { startTime: '13.45', endTime: '14.00', name: 'Presentasi dari MUI' },
            { startTime: '14.00', endTime: '14.15', name: 'Presentasi dari GetVit' },
            { startTime: '14.15', endTime: '14.25', name: 'Pemilihan Pimpinan Sidang' },
            { startTime: '14.25', endTime: '14.45', name: 'Pleno II - Laporan Kinerja Tahunan / 1 tahun' },
            { startTime: '14.45', endTime: '15.00', name: 'Pandangan Umum' },
            { startTime: '', endTime: '', name: 'Pleno III - Sidang Komisi' },
            { startTime: '15.00', endTime: '15.30', name: 'Pemilihan Pimpinan Sidang Komisi' },
            { startTime: '15.30', endTime: '16.15', name: '<div class="font-bold">Coffee Break</div>' },
            { startTime: '16.15', endTime: '17.00', name: 'Sidang Komisi A - Evaluasi & Rekomendasi' },
            { startTime: '', endTime: '', name: 'Sidang Komisi B - Program Kerja' },
            { startTime: '17.00', endTime: '17.30', name: 'Pengesahan Sidang Komisi dan Penutupan' }
        ]
    },                
]

const App = () => {

    const renderSubs = (subs) => {

        return (
            <div className="mt-3 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
                {subs.map( (sub, idx) => {
                    const addClass = `bg-neutral-100 dark:bg-neutral-800 rounded-lg`

                    return (
                        <div key={idx} className={`flex space-x-10 justify-between p-3 ${(idx + 1) % 2 ? addClass : ``}`}>
                            <span>
                                {sub.startTime ? `${sub.startTime} - ${sub.endTime}` : ``}
                            </span>
                            <span className="text-end" dangerouslySetInnerHTML={{__html: sub.name}} />
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="listingSection__wrap" id="rundown">
            <span className="text-2xl font-semibold block">Rundown</span>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            <ol className="border-l border-neutral-300 dark:border-neutral-500">
                {rundowns.map( (rundown, idx) => {

                    return (
                        <li key={idx}>
                            <div className="flex-start flex items-center pt-3">
                                <div className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-neutral-300 dark:bg-neutral-500"></div>
                                {rundown.startTime ? (
                                    <p className="text-sm text-neutral-500 dark:text-neutral-300">{rundown.startTime} - {rundown.endTime}</p>
                                ) : null}
                                
                            </div>
                            <div className="mb-6 ml-4 mt-2">
                                <h4 className="mb-1.5 text-xl font-semibold">{rundown.name}</h4>
                                {/* <p className="mb-3 text-neutral-500 dark:text-neutral-300">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
                                    scelerisque diam non nisi semper, et elementum lorem ornare.
                                    Maecenas placerat facilisis mollis. Duis sagittis ligula in
                                    sodales vehicula.
                                </p> */}
                                {rundown.subs && rundown.subs.length ? renderSubs(rundown.subs) : null}
                            </div>
                        </li>
                    )
                })}
            </ol>        
        </div>        
    )
}

export default App