import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'

// import app from './slice'

const { reducer } = require('@config').default.store

export default configureStore({
    reducer: {
        // app,
        ...reducer
    },
    // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger)
})
