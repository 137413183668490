import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import DeviceEventEmitter from 'services/EventEmitter'

import { getItem, removeItem, setItem } from 'services/storage'
// import { subscription } from '@services/notification/handler'

const initialState = {
    loading: false,
    isLoggedIn: false,
    accessToken: null,
    profile: null,
    error: null,
    accounts: { loading: false, data: [], error: null, count: 0 },
    selected: {}
}

export const logOut = createAsyncThunk('account/logOut', async () => {
    const user = await getItem('@accountProfile')
    DeviceEventEmitter.emit('auth:logout:success', user)
    await removeItem('@accessToken')

    // if (typeof subscription !== 'undefined') subscription('unsubscribe')
})

export const setAccounts = createAsyncThunk(
    'account/setAccounts',
    async payload => {
        const { data, count } = payload
        if (data) await setItem('@accounts', JSON.stringify({ data, count }))
        return payload
    }
)

const slice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        logIn(state, action) {
            if (action.payload.accessToken) {
                // if (typeof subscription !== 'undefined') subscription('subscribe')
                DeviceEventEmitter.emit(
                    'auth:login:success',
                    action.payload.accessToken
                )
                state.isLoggedIn = true
            }
            state.accessToken = action.payload.accessToken
            state.profile = action.payload.profile
            state.accounts.data = action.payload.accounts.data
            state.accounts.count = action.payload.accounts.count
        },
        setAccessToken(state, action) {
            state.accessToken = action.payload
        },
        setProfile(state, action) {
            state.profile = action.payload
        },
        setAccountSelected(state, action) {
            state.selected = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(logOut.fulfilled, () => initialState)
        builder.addCase(setAccounts.fulfilled, (state, action) => {
            state.accounts = action.payload
        })
    }
})

export const { logIn, setAccessToken, setProfile, setAccountSelected } =
    slice.actions

export default slice.reducer
