import React from 'react'
import socketIOClient from 'socket.io-client'
import sailsIOJS from 'sails.io.js'
import { node } from 'prop-types'

import initialize from './initialize'

const __DEV__ = false

const SocketContext = React.createContext(null)

const SocketProvider = ({ children }) => {
    const io = React.useRef()

    const [value, setValue] = React.useState(null)

    React.useEffect(() => {
        const config = require('config').default.socket
        const options = {}
        if (socketIOClient.sails) io.current = socketIOClient
        else {
            io.current = sailsIOJS(socketIOClient)
            io.current.sails.environment = __DEV__
                ? 'development'
                : 'production'
            io.current.sails.autoConnect = false
            io.current.sails.reconnection = true
            io.current.sails.useCORSRouteToGetCookie = false
            io.current.sails.url = config.url
        }
        if (config.path) options.path = config.path
        const socket = io.current.sails.connect(options)
        initialize(socket)
        setValue(socket)
    }, [])

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    )
}
SocketProvider.propTypes = { children: node }

export const useSocket = () => {
    const context = React.useContext(SocketContext)
    if (context === undefined) {
        throw new Error('useSocket can only be used inside SocketContext')
    }
    return context
}

export default SocketProvider
