import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    page: {
        cart: {}
    }
}

const slice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setPageCart(state, action) {
            state.page.cart = action.payload
        }
    }
})

export const { setPageCart } = slice.actions
export default slice.reducer
